import React from 'react';
import { OfferField } from "./MarketplaceItem";
import { GetPackageVersionData } from '../../Api/WebApi'
import { Button } from 'react-bootstrap';
import { BUTTON_TEXT } from '../../Constants/enums';
import Auth from "../../Auth/Auth";
import { DurableOfferModel } from "./DurableOfferModel";

export interface PackageVersionData {
    versionInGLT: OfferField<string>,
    versionInGLSegment: OfferField<string>,
    versionReleased: OfferField<string>,
    versionsInWASMDLC: OfferField<string>,
    minGameVersion: OfferField<string>
}

export const getPackageVersionData = async (auth: Auth, refresh: boolean, submission: DurableOfferModel): Promise<PackageVersionData> => {
    let packageVersions = await GetPackageVersionData(auth, submission.packageName.value, refresh)
        .catch((error) => {
            console.log("error", error);
        });

    let platform = (submission.workItemPlatform.value as string).toLowerCase();
    return assignPlayfabAndWasmVersions(packageVersions, platform, submission);
}

export const getRefreshPackageVersionDataButton = (submission: DurableOfferModel, isFirstParty: boolean, parentCallBack:(refresh: boolean, submission: DurableOfferModel) => void) => {
    if (isFirstParty)
    {
        return <div className="refresh-button-container">
        <Button
            onClick={() => parentCallBack(true, submission)}
            variant={"success"} >
            {BUTTON_TEXT.REFRESH}
        </Button></div>;
    } else {
        return <div></div>
    }
}

const assignPlayfabAndWasmVersions = (packageVersions:any, platform: string, submission: DurableOfferModel) => {
    let data: PackageVersionData = {
        "versionInGLT": new OfferField<string>("Version in GLT", false),
        "versionInGLSegment": new OfferField<string>("Version in GL Segment", false),
        "versionReleased": new OfferField<string>("Version Released in GL", false),
        "versionsInWASMDLC": new OfferField<string>("Versions in WASM DLC", false),
        "minGameVersion": submission.minimumGameVersion
    };

    if (packageVersions == null || (platform !== "pc" && platform !== "xbox"))
    {
        return data;
    }

    let platformKey =  platform.concat("Version");
    let packageVersionData = packageVersions[platformKey];

    for (let prop in data) {
        if (packageVersionData.hasOwnProperty(prop) && packageVersionData[prop] !== null && packageVersionData[prop].length !== 0) {
            data[prop as keyof PackageVersionData].value = packageVersionData[prop];
        }
    }

    return data;
}


