
import { Component } from 'react';

import {  GetValidationReport } from '../Api/WebApi';


class ValidationResultGetter extends Component {
    constructor(props) {
        super(props);

        this.state = this.initialState;
        this.resultCallback = this.resultCallback.bind(this);
        this.startHydrate = this.startHydrate.bind(this);
    }

    get initialState() {
        return {
            auth: this.props.auth,
            validationResult: null
        }
    }

    componentWillUnmount(){
        console.log("unmounting!");
        clearInterval(this.timer);
    }

    componentDidMount(){
        this.timer = setInterval(() => this.startHydrate(), 3000);
        this.setState(() => ({ isMounted: true }));
    }

    resultCallback(resultObj){
        if(resultObj.inputMetadata.submissionId !== this.props.validationId){
            console.log("got erroneous report!");
        }else{
            this.props.resultCallback(resultObj);
        }
        
    }

    startHydrate(){
        const {
            auth,
        } = this.state;

        const {
            validationId,
            game
        } = this.props;

        if(!validationId){
            console.log("no validationId!")
            return;
        }

        //console.log("hydrating!!");
        
        GetValidationReport(auth, game, validationId).then((result) => {
            this.resultCallback(result);
        }).catch((error) => {
            console.log("testReportError", error);
        });
    }

    render() {
        return (
            null
        );

    }
}

export {
    ValidationResultGetter,
} 