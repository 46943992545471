import React, { Component } from 'react';

import { } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { VSO_STATE_INFO, STATUSBAR_STATES, VSO_STATES, BUTTON_STATE } from '../Constants/enums';

class StatusBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardState: this.props.cardState,
            offerType: this.props.offerType,
        };
    }

    componentDidUpdate(prevProps, prevState) {

    }
    render() {
        const {
            cardState,
            offerType,
        } = this.state;

        var statusBarStates = STATUSBAR_STATES[offerType];

        if (cardState === VSO_STATES.PARTNER) {
            statusBarStates = STATUSBAR_STATES["Partner"];
        }

        var statusRows = [];
        var statusIcon;
        var statusIconStyle = 'success';
        var stateFound = false;
        statusIcon = <FontAwesomeIcon icon={faCheckCircle} className="icon" />;
        Object.values(statusBarStates).forEach (statusBarText => {
            if (stateFound) {
                statusIcon = <FontAwesomeIcon icon={faCircle} className="icon" />;
                statusIconStyle = '';
            } else if (VSO_STATE_INFO[cardState].statusBarText.includes(statusBarText)) {
                stateFound = true;


                if (VSO_STATE_INFO[cardState].statusBarText.includes('Waiting')) {
                    statusIcon = <FontAwesomeIcon icon={faClock} className="icon" />;
                    statusIconStyle = BUTTON_STATE.WARNING;
                } else {
                    statusIconStyle = BUTTON_STATE.READY;
                }
            }

            statusRows.push(
                <li key={statusBarText} className={'has-' + statusIconStyle}>
                    <div className="icon-wrapper">{statusIcon}</div>
                    <label htmlFor={'step-' + statusBarText} className="status-label">{statusBarText}</label>
                </li>);

            console.log('Timeline State: ' + statusBarText +  ', Current State: '  + VSO_STATE_INFO[cardState].statusBarText);
        })

        return (
            <div>
                <div className='status-bar'>
                    <hr />
                    <ul className="list-unstyled">
                        {statusRows}
                    </ul>
                </div></div>
        );
    }
}

export { StatusBar };