import React from 'react';
import Auth from '../Auth/Auth'
import ReactTable from '../ReactTable';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';

interface Props {
    contentPackage: ContentPackage
}

export function ContentPackageBrowserDetails(props: Props) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Platform',
                accessor: 'platform', // String-based value accessors!
                maxWidth: 20,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.platform
                )
            },
            {
                Header: 'Draft',
                columns: [
                    {
                        id: "draftVersion",
                        Header: 'Version',
                        accessor: (row: any) => row.draft.version,
                        maxWidth: 20,
                        style: columnStyle
                    },
                    {
                        id: "draftSubmissionId",
                        Header: 'Submission Id',
                        accessor: (row: any) => row.draft.submissionId,
                        style: columnStyle
                    },
                    {
                        id: "draftStatus",
                        Header: 'Status',
                        accessor: (row: any) => row.draft.status,
                        maxWidth: 20,
                        style: columnStyle
                    }
                ]
            },
            {
                Header: 'Prerelease',
                columns: [
                    {
                        id: "prereleaseVersion",
                        Header: 'Version',
                        accessor: (row: any) => row.prerelease.version,
                        maxWidth: 20,
                        style: columnStyle
                    },
                    {
                        id: "prereleaseSubmissionId",
                        Header: 'Submission Id',
                        accessor: (row: any) => row.prerelease.submissionId,
                        style: columnStyle
                    },
                    {
                        id: "prereleaseStatus",
                        Header: 'Status',
                        accessor: (row: any) => row.prerelease.status,
                        style: columnStyle
                    }
                ]
            },
            {
                Header: 'Release',
                columns: [
                    {
                        id: "releaseVersion",
                        Header: 'Version',
                        accessor: (row: any) => row.release.version,
                        maxWidth: 20,
                        style: columnStyle
                    },
                    {
                        id: "releaseSubmissionId",
                        Header: 'Submission Id',
                        accessor: (row: any) => row.release.submissionId,
                        style: columnStyle
                    },
                    {
                        id: "releaseStatus",
                        Header: 'Status',
                        accessor: (row: any) => row.release.status,
                        maxWidth: 20,
                        style: columnStyle
                    }
                ]
            }];

    const tableData = [
        {
            platform: "PC",
            draft: {
                version: props.contentPackage.draft.value?.PC?.version ?? "",
                submissionId: props.contentPackage.draft.value?.PC?.submissionId ?? "",
                status: props.contentPackage.draft.value?.PC?.version ? (props.contentPackage.draft.value?.PC?.isIngestionError ? "Error" : props.contentPackage.draft.value?.PC?.isIngested ? "Live" : "Pending") : ""
            },
            prerelease: {
                version: props.contentPackage.prerelease.value?.PC?.version ?? "",
                submissionId: props.contentPackage.prerelease.value?.PC?.submissionId ?? "",
                status: props.contentPackage.prerelease.value?.PC?.version ? (props.contentPackage.prerelease.value?.PC?.isIngestionError ? "Error" : props.contentPackage.prerelease.value?.PC?.isIngested ? "Live" : "Pending") : ""
            },
            release: {
                version: props.contentPackage.release.value?.PC?.version ?? "",
                submissionId: props.contentPackage.release.value?.PC?.submissionId ?? "",
                status: props.contentPackage.release.value?.PC?.version ? (props.contentPackage.release.value?.PC?.isIngestionError ? "Error" : props.contentPackage.release.value?.PC?.isIngested ? "Live" : "Pending") : ""
            }
        },
        {
            platform: "Xbox",
            draft: {
                version: props.contentPackage.draft.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.draft.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.draft.value?.Xbox?.version ? (props.contentPackage.draft.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.draft.value?.Xbox?.isIngested ? "Live" : "Pending") : ""
            },
            prerelease: {
                version: props.contentPackage.prerelease.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.prerelease.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.prerelease.value?.Xbox?.version ? (props.contentPackage.prerelease.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.prerelease.value?.Xbox?.isIngested ? "Live" : "Pending") : ""
            },
            release: {
                version: props.contentPackage.release.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.release.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.release.value?.Xbox?.version ? (props.contentPackage.release.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.release.value?.Xbox?.isIngested ? "Live" : "Pending") : ""
            }
        }
    ];

    return (
        <div>
            <ReactTable
                style={{clear:'right'}}
                data={tableData}
                columns={columns}
                loading={!props.contentPackage}
                minRows={1}
                noDataText = {"No data!"}
                defaultSorted={ [] }
                defaultPageSize={10}
                disableSortBy={true}
                className="-striped -highlight"
                hideSearch={true}
                hidePagination={true} />
        </div>
    );
}
