import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { center, formStyle } from '../../Constants/styles'
import { getDeveloperV2, getPartnerV2, getUserV2 } from '../../Api/WebApi'
import Auth from '../../Auth/Auth';
import UserList from './UserList';
import { User } from '../../Models/User';
import { Publisher } from '../../Models/Publisher';
import { Developer } from '../../Models/Developer';

interface Props {
    auth: Auth;
}

function UserBrowser(props: Props) {
    const [users, setUsers] = useState<User[]>([]);
    const [publishers, setPublishers] = useState<Publisher[]>([]);
    const [developers, setDevelopers] = useState<Developer[]>([]);

    useEffect(() => {
        document.title = "User Browser | Marketplace Content Portal";

        const fetchData = async () => {
            const users: User[] = await getUserV2(props.auth).catch((e) => {
                console.log("Error retrieving users: ", e);
            });

            setUsers(users);

            const publishers: Publisher[] = await getPartnerV2(props.auth).catch((e) => {
                console.log("Error retrieving partners: ", e);
            });

            setPublishers(publishers);
            
            const developers: Developer[] = await getDeveloperV2(props.auth).catch((e) => {
                console.log("Error retrieving partners: ", e);
            });

            setDevelopers(developers);
        }

        fetchData();
    }, [props.auth]);

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card style={formStyle}>
                        <h1 style={center}>Users</h1>
                        <UserList users={users} publishers={publishers} developers={developers} auth={props.auth} />
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default UserBrowser;