import React, { useEffect, useState } from 'react';
import { FormControl, Badge, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
    tagValues?: string[];
    placeholder?: string;
    returnCallback?: any;
    options?: Tag[];
    sort?: boolean;
    singleValue?: boolean;
    disabled?: boolean;
}

export interface Tag {
    key: string;
    value: string;
}

function TagInput(props: Props) {
    const [tags, setTags] = useState<Tag[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = ((e: any) => {
        if (props.options) {
            const tag = props.options.find(o => o.value === e.target.value);
            if (tag) {
                const newTags = [...tags, tag];
                setTags(newTags);

                if (props.returnCallback) {
                    props.returnCallback(newTags.map(tag => tag.key));
                }
            }
            setInputValue('');
        } else {
            setInputValue(e.target.value);
        }
    });

    const handleInputKeyDown = ((e: any) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            if (props.options) {
                const tag = props.options.find(o => o.value === inputValue.trim());
                if (tag) {
                    const newTags = [...tags, tag];
                    setTags(newTags);

                    if (props.returnCallback) {
                        props.returnCallback(newTags.map(tag => tag.key));
                    }
                }
            } else {
                const newTags = [...tags, { key: inputValue, value: inputValue }];
                setTags(newTags);

                if (props.returnCallback) {
                    props.returnCallback(newTags.map(tag => tag.key));
                }
            }
            setInputValue('');
        }
    });

    const handleTagRemove = ((tagToRemove: Tag) => {
        const newTags = tags.filter((tag) => tag !== tagToRemove);
        setTags(newTags);
        props.returnCallback(newTags.map(tag => tag.key));
    });

    useEffect(() => {
        if (!props.tagValues) {
            setTags([]);
        } else if (!props.options) {
            setTags(props.tagValues.map(tag => ({ key: tag, value: tag })));    
        } else {
            setTags(props.options.filter(o => props.tagValues?.includes(o.key)));
        }
    }, [props.tagValues, props.options]);

    let tagOptions = props.options?.filter(option => !tags.find(tag => tag.key === option.key)).map((option, index) => <option value={option.key} key={index}>{option.value}</option>);

    if (props.sort === true) {
        tagOptions = tagOptions?.sort((a, b) => a.props.children.localeCompare(b.props.children));
    }

    return (
        <InputGroup className="tags-container" size='sm'>
            {tags.length > 0 && <InputGroup.Text>
                {tags.map((tag, index) => (
                    <Badge key={index} className="tag-badge pill" bg="primary" pill={true}>
                        {tag.value}
                        {!props.disabled && <FontAwesomeIcon icon={faXmark} className="icon" onClick={() => handleTagRemove(tag)}/>}
                    </Badge>
                ))}
            </InputGroup.Text>}
            {props.options ?
                <FormControl
                    as="select"
                    size="sm"
                    placeholder={props.placeholder}
                    disabled={props.disabled || props.singleValue && tags.length > 0}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}>
                    <option value={props.placeholder} hidden/>
                    {tagOptions}
                </FormControl> :
                <FormControl
                    type="text"
                    size="sm"
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                />}
        </InputGroup>
    );
}

export default TagInput;