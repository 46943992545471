
import {AUGER_OFFER_TYPES} from '../Constants/enums';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';

import {DurableOfferModel} from '../Models/AugerOffers/DurableOfferModel';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
//import {PersonaOfferModel} from '../Models/AugerOffers/PersonaOfferModel';

export default class AugerOfferFactory {
    static build(type, data) {
        switch (type) {
            case AUGER_OFFER_TYPES.DURABLE_OFFER :
                return new DurableOfferModel(data);
            case AUGER_OFFER_TYPES.PERSONA_OFFER :
                return null; // new PersonaOfferModel(data);
            case AUGER_OFFER_TYPES.MIS_OFFER :
                return new MarketplacePackage(data);
            case AUGER_OFFER_TYPES.CIDS_OFFER :
                return new ContentPackage(data);
            default:
                console.log('build: Offer Type Unknown',  type);
                return undefined;
        }
    }
    static getModel(type) {
        switch (type) {
            case AUGER_OFFER_TYPES.DURABLE_OFFER :
                return DurableOfferModel;
            case AUGER_OFFER_TYPES.PERSONA_OFFER :
                return null; // PersonaOfferModel;
            case AUGER_OFFER_TYPES.MIS_OFFER :
                return MarketplacePackage;
            case AUGER_OFFER_TYPES.CIDS_OFFER :
                return ContentPackage;
            default:
                console.log('getModel: Offer Type Unknown',  type);
                return undefined;
        }
    }
}