import React, { Component } from 'react';

import { Dropdown, DropdownButton } from 'react-bootstrap';


export class DropdownSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            options: this.props.options,
            returnCallback: this.props.returnCallback,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selection){
        this.state.returnCallback(selection);
        this.setState(() => ({ value: selection }));
    }

    render() {
        const {
            value,
            options,
        } = this.state;

        var menuItems = options.map((option) => {
            return <Dropdown.Item disabled={option.disabled} className="dropdown-select-mi" eventKey={option.id} key={option.id}><span id="dropdown-select-span">{option.name.toUpperCase()}</span></Dropdown.Item>
        })

        var title = "";
        options.forEach(option => {
            if(option.id === value){
                title = option.name;
            }
        })
        
        return (
            <DropdownButton 
                            size="lg"
                            variant="success"
                            title={title.toUpperCase()}
                            key={"dropdown-select"}
                            id={"dropdown-select"}
                            onSelect={(event) => this.handleChange(event)}
                        >
                        {menuItems}
            </DropdownButton>
    )
        

        ;
    }
}