import React, { useEffect, useState } from 'react';
import { Developer } from '../../Models/Developer';
import { Alert, Button, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { formStyle } from '../../Constants/styles';
import { createDeveloperV2, updateDeveloperV2 } from '../../Api/WebApi';
import Auth from '../../Auth/Auth';
import { GenericCheckboxForm, TagFormField, TextFormField } from '../FormFields/FormField';
import { Publisher } from '../../Models/Publisher';

interface Props {
    auth: Auth;
    developer: Developer;
    publishers: Publisher[];
    isNew: boolean;
    updateDeveloperCallback: any;
}

function DeveloperModal(props: Props) {
    const [developer, setDeveloper] = useState<Developer>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (props.developer) {
            setDeveloper(props.developer);
        } else {
            setDeveloper(new Developer());
        }
    }, [props.developer]);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const saveChanges = () => {
        const createNewDeveloper = async () => {
            const result: Developer = await createDeveloperV2(props.auth, developer)
                .catch((e) => {
                    console.log("Error creating developer: ", e);
                    setError(e.response.data)
                });

            if (result) {
                setDeveloper(result);
                setError(null);
                props.updateDeveloperCallback(result);
                closeModal();
            }
        }

        const updateExistingDeveloper = async () => {
            const result: Developer = await updateDeveloperV2(props.auth, developer)
                .catch((e) => {
                    console.log("Error updating developer: ", e);
                    setError(e.response.data)
                });

            if (result) {
                setDeveloper(result);
                setError(null);
                props.updateDeveloperCallback(result);
                closeModal();
            }
        }

        if (props.isNew) {
            createNewDeveloper()
        } else {
            updateExistingDeveloper()
        }
    };

    const updateDeveloperProperty = function <V>(developer: Developer | undefined, propertyName: keyof Developer, value: V) {
        setDeveloper((prevDeveloper: any) => ({
            ...prevDeveloper,
            [propertyName]: value,
        }));
    };

    const getDeveloperFormFields = (): JSX.Element[] => {
        let formFields: JSX.Element[] = [];
      
        if (developer === null || developer === undefined) {
          return formFields;
        }
    
        formFields.push(<TextFormField<Developer>
            key={"developerId"}
            value={(developer?.id) ? developer.id : ''}
            title={"Developer Id"}
            placeholder={"Developer Id"}
            helpContent={"Partner Developer Id"}
            propertyName={"id"}
            returnCallback={updateDeveloperProperty}
            isNew={props.isNew}
            disabled={!props.isNew} // Form Field enabled only when creating a new partner
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Developer>
            key={"name"}
            value={(developer?.name) ? developer.name : ''}
            title={"Name"}
            placeholder={"Name"}
            helpContent={"Developer Name"}
            propertyName={"name"}
            returnCallback={updateDeveloperProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TagFormField<Developer>
            key={"publishers"}
            context={developer}
            value={developer?.publishers}
            title={"Publishers"}
            placeholder={"Publisher Id"}
            helpContent={"Type in the id of a publisher, and hit enter."}
            propertyName={"publishers"}
            returnCallback={updateDeveloperProperty}
            isNew={props.isNew}
            disabled={false}
            optionsList={props.publishers?.map(p => { return { key: p.id, value: p.id } } )}
            offerUndo={false} />);

        formFields.push(<GenericCheckboxForm<Developer>
            key={"isApproved"}
            value={developer.isApproved}
            title={"Approved"}
            helpContent={"Approved"}
            propertyName={"isApproved"}
            returnCallback={updateDeveloperProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Approved'}]} 
            offerUndo={false} />);
        
        return formFields;
    };

    return (
        <div className='modal-container'>
            <div className='modal-button'>
                {!props.isNew && <FontAwesomeIcon icon={faPenToSquare} className="icon" onClick={openModal}/>}
                {props.isNew && <Button variant="success" size="sm" onClick={openModal}>CREATE NEW DEVELOPER</Button>}
            </div>
            <div className="developerModal">
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{(props.isNew) ? 'Create New Developer' : props.developer?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={formStyle}>
                            {getDeveloperFormFields()}
                        </Card>
                        <div>
                            {error && (
                                <Alert variant="danger" onAbort={() => {setError(null)}}>
                                    <h4>Error:</h4>
                                    <p>{error}</p>
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={saveChanges}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
      );
}

export default DeveloperModal;