
export const SPLASH_TEXT = [
    "Players First, Partners First",
    "Thanks Todd!",
    "DON'T EGRESS THE DATA CENTER",
    "RIP Partner Valley",
    "ASSERT",
    "Beware 4K textures",
    "I EGRESSED THE DATA CENTER",
    "Price Elasticity. Google it.",
    "100% pentagram free",
    "Fully ring-fenced.",
    "Ask Troyer",
    "Greylisted on mid-tier",
    "Closed for PS4 port",
    "Don't modify UI, k thx",
    "Always blame encryption",
    "Powered by GeoCities",
    "Jannis is the real MVP",
    "That's broken in R8",
    "My dashboard is down",
    "Invalid JSON"
]