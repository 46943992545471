export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const CONTENT_BROWSER_ROOT = '/contentBrowser/';
export const CONTENT_BROWSER = CONTENT_BROWSER_ROOT + 'Durable';
export const CREATE_NEW_OFFER = '/createNewOffer';
export const CREATE_UPDATE = '/CreateUpdate/:offerId';
export const OFFER_DETAILS = '/viewOffer/:offerId';
export const REPORT_DETAILS = '/viewReport/:reportId';
export const VALIDATION_REPORT_DETAILS = '/viewValidationReport/:reportId';
export const SIGN_OUT = '/signout';
export const VALIDATOR = '/validator';
export const USER_BROWSER = '/userBrowser';
export const PARTNER_BROWSER = '/partnerBrowser';
