import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Auth from './Auth/Auth';
import { Card, Image } from 'react-bootstrap';
import { center, formStyle, msStudiosLogoFooter } from './Constants/styles';
import version from './version.json';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import logoImage from './Images/microsoft_studios.png';

const auth = new Auth();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render((() => {
    if (process.env.REACT_APP_OFFLINE_FOR_MAINTENANCE === 'true' && auth.isExternal()) {
      const message = process.env.REACT_APP_OFFLINE_MESSAGE;
  
      return (
        <main className="container">
          <div className="row">
            <Card style={formStyle} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div style={center}>
                <h4>{message}</h4>
              </div>
            </Card>
          </div>
        </main>
      );
    } else {
      return (
        <div>
          <BrowserRouter>
          <App auth={auth} />
          </BrowserRouter>
          <Image src={logoImage} style={msStudiosLogoFooter} thumbnail />
          {<footer style={{textAlign: "center", color:"white"}}>
  
          Microsoft &copy; 2018 - 2025 | Xbox Game Studios | Internal and MSFS Marketplace Partner Use Only | {version.version} <br/> <a style={{color:"white"}} href="attributions.txt" download>Attributions</a></footer>}
        </div>
      );
    }
  })());
