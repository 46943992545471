import { errorText, standardText, successText, updateText, warningText } from "./styles";
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const FILE_STATUS = {
    META_ONLY: "META_ONLY"
};

export const AUGER_OFFER_TYPES = {
    DURABLE_OFFER : "Durable",
    PERSONA_OFFER : "Persona",
    MIS_OFFER : "MIS",
    CIDS_OFFER : "CIDS"
}

export const UPDATE_STATUS = {
    notstarted: "Not started.",
    pending: "In Progress...",
    success: "Complete!",
    failed: "Failed.",
};

export const WORK_FLOW_TYPES = {
    DIRECT_PUBLISH: "DirectPublish",
    METADATA_ONLY: "MetaDataOnly",
    OFFER: "Offer",
};

export const CLOSED_WORK_FLOWS = 
{
    [WORK_FLOW_TYPES.DIRECT_PUBLISH] : "Price Change Only",
    [WORK_FLOW_TYPES.METADATA_ONLY]  : "Just the meta data (no binary)",
    [WORK_FLOW_TYPES.OFFER]          : "Everything",
};

export const INITIAL_WORK_FLOWS = 
{
    [WORK_FLOW_TYPES.METADATA_ONLY]  : CLOSED_WORK_FLOWS[WORK_FLOW_TYPES.METADATA_ONLY],
    [WORK_FLOW_TYPES.OFFER]          : CLOSED_WORK_FLOWS[WORK_FLOW_TYPES.OFFER],
};

//Max upload size in MB
export const MAX_UPLOAD_SIZE = 50 * 1024 * 1024 * 1024;

const DECISION_TYPE = {
    Pass:"Pass",
    Fail: "Fail",
    PassWithNotes: "Pass with notes",
    Investigation: "Investigation"
};

const REPORT_DECISIONS = {
    PASS:"Pass",
    FAIL: "Fail",
    PASS_WITH_NOTES: "PassWithNotes",
    INVESTIGATING: "Investigating"
};

const READABLE_REPORT_DECISIONS = {
    [REPORT_DECISIONS.PASS] : "PASS",
    [REPORT_DECISIONS.FAIL] : "FAIL",
    [REPORT_DECISIONS.PASS_WITH_NOTES] : "PASS WITH NOTES",
    [REPORT_DECISIONS.INVESTIGATING] : "INVESTIGATING"
}

const POLICY_DECISIONS = {
    PASS:"Pass",
    FAIL: "Fail",
    PASS_WITH_NOTES: "PassWithNotes",
    INVESTIGATING: "Investigate"
}

const DARSY_TASK_TYPE = {
    DarsyMPQA: "Certification",
    DarsyMPCR: "Content Review",
};

const VSO_STATES = {
    PLANNED: "Planned",
    SUBMITTED: "Content Submitted",
    RESUBMITTED: "Content Resubmitted",
    RECEIVED: "Content Received",
    INGESTED: "Content Ingested",
    GLT_TESTING_REQUESTED: "GLT Testing Requested",
    GLT_TESTING_READY: "GLT Ready for Testing",
    GLT_TESTING: "GLT In Testing",
    GLT_TESTED: "GLT Testing Completed",
    GL_SANITY_TESTING_REQUESTED: "Sanity GL Testing Requested",
    GL_SANITY_TESTING_READY: "Sanity GL Ready for Testing",
    GL_SANITY_TESTING: "Sanity GL In Testing",
    GL_SANITY_TESTED: "Sanity GL Testing Completed",
    TESTING_REQUESTED: "Content Testing Requested",
    TESTING: "Content In Testing",
    TESTED: "Content Testing Completed",
    RELEASED: "Content Released",
    PARTNER: "Content Action Required",
    DELISTED: "Content Delisted"
}

const READABLE_VSO_STATES = {
    PLANNED: "Planned",
    SUBMITTED: "Submitted",
    RESUBMITTED: "Resubmitted",
    RECEIVED: "Received",
    INGESTED: "Ingested",
    TESTING: "Testing Started",
    TESTED: "Testing Completed",
    RELEASED: "Released",
    PARTNER: "Action Required",
    DELISTED: "Delisted"
}

const VSO_PARTNER_TESTING_STATES = {
    NOT_STARTED: "Not Started",
    REQUESTED: "Requested",
    STARTED: "Started",
    SIGNED_OFF: "Signed Off",
    FAILED: "Failed",
    RESUBMISSION_REQUESTED: "Resubmission Requested"
}

const READABLE_VSO_PARTNER_TESTING_STATES = {
    NOT_STARTED: "Not Ready for Testing",
    REQUESTED: "Ready for Testing",
    STARTED: "Testing Started",
    SIGNED_OFF: "Signed off for Release",
    FAILED: "Failed for Release",
    RESUBMISSION_REQUESTED: "Resubmission Requested"
}

const BUTTON_TEXT = {
    SUBMIT: "SUBMIT",
    RESUBMIT: "RESUBMIT",
    VERIFY: "VERIFY",
    UPDATE: "UPDATE",
    EDIT: "EDIT",
    TAKEDOWN: "TAKE DOWN",
    ATTACH: "ATTACH FILE",
    REFRESH: "REFRESH"
}

const BUTTON_TOOLTIP = {
    SUBMIT: "SUBMIT",
    RESUBMIT: "RESUBMIT",
    VERIFY: "VERIFY",
    UPDATE: "UPDATE",
    EDIT: "EDIT",
    TAKEDOWN: "TAKE DOWN",
    ATTACH: "ATTACH FILE",
    TESTING_STARTED: "Testing has started on this content - please contact the Flight Simulator team if you need to resubmit.",
    TESTING_COMPLETED: "Testing has finished on this content - please contact the Flight Simulator team if you need to resubmit.",
    PARTNER_ACTION_REQUIRED: "Action required - please address any issues reported and resubmit, or contact the Flight Simulator team for more information."
}

const BUTTON_STATE = {
    INVALID: "default",
    READY: "success",
    WARNING: "warning",
    DANGER: "danger",
}

const STATUS_BAR_TEXT = {
    WAITING_RECEIVED: "Waiting for Received",
    RECEIVED: "Received",
    WAITING_INGESTED: "Waiting for Ingested",
    INGESTED: "Ingested",
    WAITING_TESTING_STARTED: "Waiting for MS Testing Started",
    TESTING: "MS Testing Started",
    WAITING_TESTING_COMPLETED: "Waiting for MS Testing Completed",
    TESTED: "MS Testing Completed",
    WAITING_RELEASED: "Waiting for Released",
    PARTNER: "Action Required",
    RELEASED: "Released",
    BLANK: ""
}

const BUTTON_ACTION = {
    UPDATE: "update",
    DETAILS: "details",
}

const VSO_STATE_INFO = {
    [VSO_STATES.PLANNED]: {
        status: READABLE_VSO_STATES.PLANNED,
        updateable: true,
        newstate: VSO_STATES.PLANNED,
        buttonText: BUTTON_TEXT.SUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.SUBMIT,
        buttonState: BUTTON_STATE.READY,
        statusBarText: STATUS_BAR_TEXT.WAITING_RECEIVED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.SUBMITTED]: {
        status: READABLE_VSO_STATES.SUBMITTED,
        updateable: true,
        newstate: VSO_STATES.RESUBMITTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.RESUBMIT,
        buttonState: BUTTON_STATE.WARNING,
        statusBarText: STATUS_BAR_TEXT.WAITING_RECEIVED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.RESUBMITTED]: {
        updateable: true,
        status: READABLE_VSO_STATES.RESUBMITTED,
        newstate: VSO_STATES.RESUBMITTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.RESUBMIT,
        buttonState: BUTTON_STATE.WARNING,
        statusBarText: STATUS_BAR_TEXT.WAITING_RECEIVED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.RECEIVED]: {
        status: READABLE_VSO_STATES.RECEIVED,
        updateable: true,
        newstate: VSO_STATES.RESUBMITTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.RESUBMIT,
        buttonState: BUTTON_STATE.WARNING,
        statusBarText: STATUS_BAR_TEXT.WAITING_INGESTED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.INGESTED]: {
        updateable: true,
        newstate: VSO_STATES.RESUBMITTED,
        status: READABLE_VSO_STATES.INGESTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.RESUBMIT,
        buttonState: BUTTON_STATE.WARNING,
        statusBarText: STATUS_BAR_TEXT.INGESTED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GLT_TESTING_REQUESTED]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_STARTED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GLT_TESTING_READY]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_STARTED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GLT_TESTING]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GLT_TESTED]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GL_SANITY_TESTING_REQUESTED]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GL_SANITY_TESTING_READY]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GL_SANITY_TESTING]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.GL_SANITY_TESTED]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_COMPLETED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_RELEASED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.TESTING_REQUESTED]: {
        updateable: false,
        newstate: VSO_STATES.RESUBMITTED,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.RESUBMIT,
        buttonState: BUTTON_STATE.WARNING,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_STARTED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.TESTING]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTING,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_STARTED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_TESTING_COMPLETED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.TESTED]: {
        updateable: false,
        status: READABLE_VSO_STATES.TESTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.TESTING_COMPLETED,
        buttonState: BUTTON_STATE.INVALID,
        statusBarText: STATUS_BAR_TEXT.WAITING_RELEASED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.RELEASED]: {
        updateable: true,
        status: READABLE_VSO_STATES.RELEASED,
        buttonText: BUTTON_TEXT.UPDATE,
        buttonTooltip: BUTTON_TOOLTIP.UPDATE,
        newstate: VSO_STATES.SUBMITTED,
        buttonState: BUTTON_STATE.READY,
        statusBarText: STATUS_BAR_TEXT.RELEASED,
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.PARTNER]: {
        updateable: true,
        status: READABLE_VSO_STATES.PARTNER,
        newstate: VSO_STATES.RESUBMITTED,
        buttonText: BUTTON_TEXT.RESUBMIT,
        buttonTooltip: BUTTON_TOOLTIP.PARTNER_ACTION_REQUIRED,
        buttonState: BUTTON_STATE.DANGER,
        statusBarText: STATUS_BAR_TEXT.PARTNER,
        statusBarTooltip: "Please contact the Marketplace team",
        action: BUTTON_ACTION.UPDATE
    },
    [VSO_STATES.DELISTED]: {
        updateable: true,
        status: READABLE_VSO_STATES.DELISTED,
        newstate: VSO_STATES.RESUBMITTED,
        buttonText: BUTTON_TEXT.UPDATE,
        buttonTooltip: BUTTON_TOOLTIP.UPDATE,
        buttonState: BUTTON_STATE.READY,
        statusBarText: STATUS_BAR_TEXT.WAITING_RECEIVED,
        action: BUTTON_ACTION.UPDATE
    }
};

const VSO_PARTNER_TESTING_STATE_INFO = {
    [VSO_PARTNER_TESTING_STATES.NOT_STARTED]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.NOT_STARTED,
        style: standardText,
        newstate: VSO_PARTNER_TESTING_STATES.REQUESTED,
        buttonText: "Request Partner Testing",
        buttonEnabledForFirstParty: true,
        buttonEnabledForThirdParty: false,
        enableRequestResubmission: true
    },
    [VSO_PARTNER_TESTING_STATES.REQUESTED]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.REQUESTED,
        style: warningText,
        newstate: VSO_PARTNER_TESTING_STATES.STARTED,
        buttonText: "Confirm Testing Started",
        buttonEnabledForFirstParty: false,
        buttonEnabledForThirdParty: true,
        enableRequestResubmission: true
    },
    [VSO_PARTNER_TESTING_STATES.STARTED]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.STARTED,
        altStatus: "Sign Off for Release?",
        style: warningText,
        altStyle: updateText,
        newstate: VSO_PARTNER_TESTING_STATES.SIGNED_OFF,
        altNewState: VSO_PARTNER_TESTING_STATES.FAILED,
        buttonText: "PASS",
        altButtonText: "FAIL",
        buttonEnabledForFirstParty: false,
        buttonEnabledForThirdParty: true,
        enableRequestResubmission: true
    },
    [VSO_PARTNER_TESTING_STATES.SIGNED_OFF]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.SIGNED_OFF,
        style: successText,
        newstate: VSO_PARTNER_TESTING_STATES.NOT_STARTED,
        buttonText: "",
        buttonEnabledForFirstParty: false,
        buttonEnabledForThirdParty: false,
        enableRequestResubmission: true
    },
    [VSO_PARTNER_TESTING_STATES.FAILED]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.FAILED,
        style: errorText,
        newstate: VSO_PARTNER_TESTING_STATES.NOT_STARTED,
        buttonText: "",
        buttonEnabledForFirstParty: false,
        buttonEnabledForThirdParty: false,
        enableRequestResubmission: true
    },
    [VSO_PARTNER_TESTING_STATES.RESUBMISSION_REQUESTED]: {
        status: READABLE_VSO_PARTNER_TESTING_STATES.RESUBMISSION_REQUESTED,
        style: warningText,
        newstate: VSO_PARTNER_TESTING_STATES.NOT_STARTED,
        buttonText: "",
        buttonEnabledForFirstParty: false,
        buttonEnabledForThirdParty: false,
        enableRequestResubmission: false
    }
};

const STATUSBAR_STATES = {
    "Durable": [
        STATUS_BAR_TEXT.RECEIVED,
        STATUS_BAR_TEXT.INGESTED,
        STATUS_BAR_TEXT.TESTING,
        STATUS_BAR_TEXT.TESTED,
        STATUS_BAR_TEXT.RELEASED
        ],
    "Partner": [
        STATUS_BAR_TEXT.RECEIVED,
        STATUS_BAR_TEXT.INGESTED,
        STATUS_BAR_TEXT.PARTNER,
        STATUS_BAR_TEXT.BLANK,
        STATUS_BAR_TEXT.BLANK
        ],
    "MIS": [
        STATUS_BAR_TEXT.RECEIVED,
        STATUS_BAR_TEXT.INGESTED,
        STATUS_BAR_TEXT.TESTING,
        STATUS_BAR_TEXT.TESTED,
        STATUS_BAR_TEXT.RELEASED
        ],
    };

const ROYALTY_TIERS = [];

const VALIDATION_DECISIONS = {
    PASS: "Pass",
    FAIL: "Fail",
    PASS_WITH_WARNING: "PassWithWarning",
    PENDING: "Pending",
    TIMEOUT: "Timeout",
    UNKNOWN: "Unknown"
}

const VALIDATION_DECISION_INFO = {
    [VALIDATION_DECISIONS.PASS]: {
        readable: "Pass",
        viewable: true,
        labelStyle: "success",
        symbol: faCheckCircle,
        color: "#43ac6a"
    },
    [VALIDATION_DECISIONS.FAIL]: {
        readable: "Fail",
        viewable: true,
        labelStyle: "danger",
        symbol: faCheckCircle,
        color: "#f04124"
    },
    [VALIDATION_DECISIONS.PASS_WITH_WARNING]: {
        readable: "Pass w/Warning",
        viewable: true,
        labelStyle: "warning",
        symbol: faCheckCircle,
        color: "#e99002"
    },
    [VALIDATION_DECISIONS.PENDING]: {
        readable: "Pending",
        viewable: false,
        symbol: faCheckCircle,
        color: ""
    },
    [VALIDATION_DECISIONS.TIMEOUT]: {
        readable: "Timeout",
        viewable: false,
        symbol: faClock,
        color: "#a9a9a9"
    },
    [VALIDATION_DECISIONS.UNKNOWN]: {
        readable: "Unknown",
        viewable: false,
        symbol: faQuestionCircle,
        color: "#a9a9a9"
    }
};
export const TEST_DECISION = {
    ERROR: "Error",
    SPECIAL_WARNING: "Warning",
    WARNING: "SpecialWarning",
}

export const TEST_DECISION_INFO = {
    [TEST_DECISION.ERROR]: {
        readable: "Error",
        color: "#f04124"
    },
    [TEST_DECISION.SPECIAL_WARNING]: {
        readable: "Special Warning",
        color: "#e95902"
    },
    [TEST_DECISION.WARNING]: {
        readable: "Warning",
        color: "#e99002"
    }
};
export {
    VSO_STATE_INFO,
    VSO_PARTNER_TESTING_STATE_INFO,
    FILE_STATUS,
    ROYALTY_TIERS,
    STATUSBAR_STATES,
    STATUS_BAR_TEXT,
    DECISION_TYPE,
    DARSY_TASK_TYPE,
    REPORT_DECISIONS,
    READABLE_REPORT_DECISIONS,
    READABLE_VSO_STATES,
    POLICY_DECISIONS,
    VSO_STATES,
    VSO_PARTNER_TESTING_STATES,
    BUTTON_STATE,
    BUTTON_TEXT,
    BUTTON_ACTION,
    VALIDATION_DECISIONS,
    VALIDATION_DECISION_INFO
};
