import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { GetAttachments, GetPipelineRun, GetSubmission, IngestSubmission, InitialSignOffToCarryOverProduct, UpdateMetaData, UploadFile } from '../Api/WebApi';
import { PackageVersionData, getPackageVersionData, getRefreshPackageVersionDataButton } from '../Models/AugerOffers/PackageVersionModel';
import { StatusBar } from './StatusBar';
import { TestReports } from './DisplayInfo/TestReports';
import { CommentsDetails } from './Forms/Comments';
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import * as utils from '../Constants/utils';
import { VSO_PARTNER_TESTING_STATES } from '../Constants/enums';
import * as styles from '../Constants/styles';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { DurableOfferModel, PackTypes } from '../Models/AugerOffers/DurableOfferModel';
import Auth from '../Auth/Auth';
import { DisplayField } from '../Models/AugerOffers/MarketplaceItem';
import { UserAccessLevel } from '../Models/User';

async function getSubmission(auth: Auth, offerId: string | undefined) {
    let result = await GetSubmission(auth, offerId);

    result.auth = auth;

    return AugerOfferFactory.build(result.offerType, result) as DurableOfferModel;
}

async function getLinkedSubmissions(auth: Auth, offerId: string | undefined) {
    let s = await getSubmission(auth, offerId);

    if (s?.tempLinkedItem.value) {
        s.linkedSubmission = await getSubmission(auth, s.tempLinkedItem.value as string);

        if (s.linkedSubmission) {
            s.linkedSubmission.linkedSubmission = s;
        }
    }

    return s;
}

interface Props {
    auth: Auth;
}
  
function ViewOffer(props: Props) {
    const params = useParams();

    const [attachments, setAttachments] = useState([]);
    const [submission, setSubmission] = useState<DurableOfferModel | null | undefined>(null);
    const [pipelineRun, setPipelineRun] = useState<any | null>(null);
    const [newPartnerTestingState, setNewPartnerTestingState] = useState<string | null>(null);
    const [newPartnerTestingStateAccepted, setNewPartnerTestingStateAccepted] = useState(false);
    const [newReadyToIngestState, setNewReadyToIngestState] = useState<boolean | undefined>();
    const [newReadyToIngestStateAccepted, setNewReadyToIngestStateAccepted] = useState(false);
    const [newCarryOverSignedOffState, setNewCarryOverSignedOffState] = useState<boolean | null>(null);
    const [newCarryOverSignedOffStateAccepted, setNewCarryOverSignedOffStateAccepted] = useState(false);
    const [updateSibling, setUpdateSibling] = useState(false);
    const [packageVersioning, setPackageVersioning] = useState<(JSX.Element | undefined)[]>([]);
    const [updatingMetaData, setUpdatingMetaData] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let submission = await getLinkedSubmissions(props.auth, params.offerId);

            if (submission) {
                document.title = "View Offer : " + submission.offerTitle.value + " | Marketplace Content Portal";

                setSubmission(submission);

                setUpdateSibling(submission.ingestionPlatforms?.value === 'PC & Xbox' && submission.linkedSubmission?.ingestionPlatforms?.value === 'PC & Xbox');

                if (submission.binaryLocation.value) {
                    let attachmentsResult = await GetAttachments(props.auth, submission.offerType.value, submission.binaryLocation.value, submission.shortCreatorName.value)
                    .catch((error) => {
                        console.log('error for getting attachment details', error);
                        setSubmission(submission);
                    });

                    setAttachments(attachmentsResult.result);
                }

                if (submission.pipelineRunId.value) {
                    var pipelineRun = await GetPipelineRun(props.auth, submission.pipelineRunId.value);

                    setPipelineRun(pipelineRun);
                } else {
                    setPipelineRun({
                        result: "succeeded",
                        state: "completed"
                    })
                }

                if (props.auth.isInternal()) {
                    await retrievePackageVersion(false, submission);
                }
            }
        }

        fetchData();
    }, [props.auth, params.offerId]);

    useEffect(() => {
        if (submission && newPartnerTestingState && newPartnerTestingStateAccepted) {
            submission.partnerTestingState.value = newPartnerTestingState;

            submission.logComment.value = newPartnerTestingState === VSO_PARTNER_TESTING_STATES.REQUESTED ? `Testing requested by ${props.auth.getUserName()}.` :
                                          newPartnerTestingState === VSO_PARTNER_TESTING_STATES.STARTED ? `Testing confirmed started by ${props.auth.getUserName()}.` :
                                          newPartnerTestingState === VSO_PARTNER_TESTING_STATES.SIGNED_OFF ? `Signed off by ${props.auth.getUserName()}.` : `Failed by ${props.auth.getUserName()}.`;

            setSubmission(submission);
            setNewPartnerTestingState(null);
            setNewPartnerTestingStateAccepted(false);

            let updateInfo = {
                cardId: submission.cardId.value,
                partnerTestingState: submission.partnerTestingState.value,
                logComment: submission.getLogComment(false)
            };
            
            console.log("updateInfo:", updateInfo);

            console.log("Partner Request Resubmission Update", updateInfo);

            setUpdatingMetaData(true);

            UpdateMetaData(props.auth, updateInfo).then(() => {
                setUpdatingMetaData(false);
            });
        }

    }, [props.auth, newPartnerTestingState, newPartnerTestingStateAccepted, submission]);

    function getFormattedComments(comments: any[]) {

        if (comments.length === 0) {
            return "";
        }

        var formattedComments = comments.map((item, index) => {
            if (item) {
                return (
                    <tr key={index}>
                        <td id={item.id} className="value" colSpan={2}>
                            <CommentsDetails value={item} submission={index} key={('comment-' + index)} />
                        </td>
                    </tr>
                );
            }
            else {
                return (<tr><td colSpan={2}></td></tr>);
            }
        });

        return (

            <table className="table table-hover">
                <thead>
                    <tr><th colSpan={2}>Change Log History:</th></tr>
                </thead>
                <tbody>
                    {formattedComments}
                </tbody>
            </table>);
    }

    function onPartnerTestingAction(newState: any) {
        setNewPartnerTestingState(newState);
        setNewPartnerTestingStateAccepted(newState === VSO_PARTNER_TESTING_STATES.SIGNED_OFF || newState === VSO_PARTNER_TESTING_STATES.FAILED ? false : true);
    }

    function onPartnerTestingActionConfirmation(confirmed: boolean) {
        if (confirmed) { 
            setNewPartnerTestingStateAccepted(true);
        } else {
            setNewPartnerTestingState(null);
        }
    }

    function onPartnerRequestResubmission() {
        setNewPartnerTestingState(VSO_PARTNER_TESTING_STATES.RESUBMISSION_REQUESTED);
    }

    async function updateSubmission(s: DurableOfferModel, r: boolean | undefined) {
        if (s && s.readyToIngest.value !== r) {
            s.readyToIngest.value = r;

            var updateInfo = s.exportForSubmission(false, false);
            console.log('updateInfo:', updateInfo);

            console.log('Ready to Ingest Update', updateInfo);

            setUpdatingMetaData(true);

            UpdateMetaData(props.auth, {
                cardId: s.cardId.value,
                readyToIngest: s.readyToIngest.value
            }).then(() => {
                let blobFile = new Blob([JSON.stringify(updateInfo, null, "\t")]);

                UploadFile(props.auth, blobFile, `${updateInfo.binaryLocation}contentInfo.json`, null, "uploadUri", updateInfo.creatorId).then(() => {
                    console.log("Metadata uploaded.")
                }).then(() => {
                    setUpdatingMetaData(false);
                })
                .catch((error) => {
                    console.log("metaUploadError", error);

                    setUpdatingMetaData(false);
                });
            });
        }
    }

    useEffect(() => {
        async function UpdateSubmissions() {
            if (submission) {
                await updateSubmission(submission, newReadyToIngestState);

                if (updateSibling && submission.linkedSubmission) {
                    await updateSubmission(submission.linkedSubmission, newReadyToIngestState);
                }

                setNewReadyToIngestState(undefined);
                setNewReadyToIngestStateAccepted(false);
                setUpdateSibling(false);
            }
        }

        if (newReadyToIngestState !== undefined && newReadyToIngestStateAccepted) {
            UpdateSubmissions();
        }
    }, [newReadyToIngestState, newReadyToIngestStateAccepted, submission]);

    useEffect(() => {
        async function UpdateSubmissions() {
            if (submission) {
                try {
                    await InitialSignOffToCarryOverProduct(props.auth, submission.packageName.value, submission.workItemPlatform.value);

                    submission.carryOverSignedOff.value = true;
                } catch (error) {
                    console.log("Error updating carry over signed off status", error);
                }

                setNewCarryOverSignedOffState(null);
                setNewCarryOverSignedOffStateAccepted(false);
            }
        }

        if (newCarryOverSignedOffState !== null && newCarryOverSignedOffStateAccepted) {
            UpdateSubmissions();
        }
    }, [newCarryOverSignedOffState, newCarryOverSignedOffStateAccepted, submission]);

    function onPartnerReadyToIngest() {
        setNewReadyToIngestState(true);
        setNewReadyToIngestStateAccepted(false);
    }

    function onPartnerReadyToIngestConfirmation(confirmed: boolean) {
        if (confirmed) { 
            setNewReadyToIngestStateAccepted(true);
        } else {
            setNewReadyToIngestState(undefined);
        }
    }

    function onCarryOverSignedOff() {
        setNewCarryOverSignedOffState(true);
        setNewCarryOverSignedOffStateAccepted(false);
    }

    function onCarryOverSignedOffConfirmation(confirmed: boolean) {
        if (confirmed) { 
            setNewCarryOverSignedOffStateAccepted(true);
        } else {
            setNewCarryOverSignedOffState(null);
        }
    }

    function onIngest() {
        if (submission) {
            let offerInfo = submission.exportForSubmission(false);

            IngestSubmission(props.auth, offerInfo);
        }
    }

    async function retrievePackageVersion(refresh: boolean, submission: DurableOfferModel) {
        if (submission) {
            let packageVersionData = await getPackageVersionData(props.auth, refresh, submission)
            .catch((error) => {
                console.log('error for getting version details', error);
            setPackageVersioning([]);
            });
        
            if (packageVersionData) {
                let packageVersionDisplayFields: DisplayField<string>[] = [];

                for (let field in packageVersionData) {
                    packageVersionData[field as keyof PackageVersionData].convertToDisplayField(packageVersionDisplayFields);
                }

                setPackageVersioning(packageVersionDisplayFields.map(utils.FormatAsRow));
            }
        }
    }

    if (submission) {
        const isFirstParty = props.auth.isInternal();
        const visibleProductDetails = submission ? submission.getVisibleProductDetails() : null;
        const comments = submission ? getFormattedComments(submission.getComments()) : null;
        const submissionStatus = submission ? submission.getSubmissionStatusDetails() : null;
        const partnerTestingStatus = submission ? submission.getSubmissionPartnerTestingStatusDetails(isFirstParty) : null;
        const approvalNotice = submission ? submission.getApprovalNotice() : null;
        const ingestControl = isFirstParty ? submission.getIngestButton(onIngest) : null;
        const newPartnerTestingStateConfirmationText =
            newPartnerTestingState === VSO_PARTNER_TESTING_STATES.RESUBMISSION_REQUESTED ?
                "Requesting resubmission may delay the release of this content." :
            newPartnerTestingState === VSO_PARTNER_TESTING_STATES.SIGNED_OFF ?
                'Designating this content as "PASS" will mark it for inclusion in the next available release window.' :
                'Designating this content as "FAIL" will mark it for exclusion from the next available release window.';

        const availableOnXPP = attachments === null ? null : (attachments.find((attachment: any) => attachment.filename.endsWith("_xpp.zip")) === undefined ? false : true);

        const xppStatusText = availableOnXPP === null || pipelineRun === null ?                         "Checking..." :
                              availableOnXPP ?                                                          "Available" :
                              pipelineRun.state === "inProgress" ?                                      "In Progress" :
                              pipelineRun.result === "succeeded" && pipelineRun.state === "completed" ? "Not Available" :
                                                                                                        "Processing failed, please contact your Content Manager";

        const isReadyForSignOff = submission.carryOverContentStatus.value === "Release Successful" && submission.carryOverMarketplaceStatus.value === "Release Successful";
        const isSignedOff = submission.carryOverSignedOff.value ?? false;

        return (
            <div className="container details" >
                <div className="row">
                    <main className="col-md-8 col-xs-12 ">
                        <Card text={"success"}>
                            <h1 className="text-center" style={{ marginTop: 10 }}>{submission.cardTitle.value as string}</h1>
                            <h4>Microsoft Status</h4>
                            <StatusBar cardState={submission.cardState.value} offerType={submission.offerType.value} />
                            <table className="table table-hover">
                                <tbody>
                                    {visibleProductDetails}
                                </tbody>
                            </table>
                            {comments}
                        </Card>
                        {isFirstParty && <Card className="refresh-well-container">
                        <div className="refresh-table-container">
                            <h4>{submission.workItemPlatform.value as string} Package Version</h4>
                            <table className="table-condensed table table-hover">
                                <tbody>
                                    {packageVersioning}
                                </tbody>
                            </table>
                        </div>
                        { packageVersioning && packageVersioning.length > 0 && getRefreshPackageVersionDataButton(submission, isFirstParty, retrievePackageVersion) }
                        </Card>}
                    </main>
                    <aside className="col-md-4 col-xs-12 ">
                        <Card>
                            <h4>Submission Status</h4>
                            <table className="table-condensed table table-hover">
                                <tbody>
                                    {submissionStatus}
                                </tbody>
                            </table>
                            <TestReports value={submission.getTestReports()} />
                        </Card>
                        {submission.workItemPlatform.value === 'Xbox' && submission.packType.value !== PackTypes.BUNDLE && submission.packType.value !== PackTypes.PACK && <Card>
                            <h4>Xbox Package Preview Status</h4>
                            {<h4 className='text-center' style={availableOnXPP ? styles.successText : styles.warningText}>{xppStatusText}</h4>}
                        </Card>}
                        {partnerTestingStatus && <Card>
                            <h4>Partner Testing Status</h4>
                            {partnerTestingStatus}
                            {submission.getPartnerTestingButtons(isFirstParty, onPartnerTestingAction)}
                        </Card>}
                        {props.auth.isInternal() && isReadyForSignOff && <Card>
                            <h4>2024 Marketplace</h4>
                            {submission.getCarryOverSignOffButton(isSignedOff, onCarryOverSignedOff)}
                        </Card>}
                        <Card>
                            <h4>Action</h4>
                            {approvalNotice}
                            {/* {approvalButton} */}
                            {submission.readyToIngest.value === false && submission.getReadyToIngestButton(isFirstParty, onPartnerReadyToIngest)}
                            {submission.getActionButton(isFirstParty, onPartnerRequestResubmission)}
                            {ingestControl}
                        </Card>
                        {props.auth.getEnableBetaFeatures() && <Card>
                            <h4>Files</h4>
                            <ul className="list-group">
                                {attachments?.filter((attachment: any) => !(attachment.filename.includes("cooked") || attachment.filename.endsWith("_xpp.zip"))).map((attachment: any, index) => (
                                    <li className="list-group-item list-group-item-primary" key={index}>
                                        <a href={attachment.url}>{attachment.filename}</a>
                                    </li>
                                ))}
                            </ul>
                        </Card>}
                        {(newPartnerTestingState && newPartnerTestingStateAccepted === false) &&
                        <Modal show={true} onHide={() => onPartnerTestingActionConfirmation(false)} backdrop={"static"} keyboard={false}>
                            <Modal.Header>
                                <Modal.Title style={styles.center}>Are You Sure?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    <div style={styles.center} >
                                        <h3 style={newPartnerTestingState === VSO_PARTNER_TESTING_STATES.SIGNED_OFF ? styles.successText : styles.errorText}>{newPartnerTestingStateConfirmationText}</h3>
                                        <span>
                                        <Button style={{margin: 10}} onClick={() => onPartnerTestingActionConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                        <Button style={{margin: 10}} onClick={() => onPartnerTestingActionConfirmation(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                                }

                            </Modal.Body>
                        </Modal>}
                        {newReadyToIngestState !== undefined && <Modal show={true} onHide={() => onPartnerReadyToIngestConfirmation(false)} backdrop={"static"} keyboard={false}>
                            <Modal.Header>
                                <Modal.Title style={styles.center}>Request ingestion for:</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    <div style={styles.center} >
                                        <h1>{updateSibling ? "PC & Xbox" : submission.workItemPlatform.value as string}</h1>
                                        <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                        <span>
                                        <Button style={{margin: 10}} onClick={() => onPartnerReadyToIngestConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                        <Button style={{margin: 10}} onClick={() => onPartnerReadyToIngestConfirmation(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                                }

                            </Modal.Body>
                        </Modal>}
                        {newCarryOverSignedOffState !== null && <Modal show={true} onHide={() => onCarryOverSignedOffConfirmation(false)} backdrop={"static"} keyboard={false}>
                            <Modal.Header>
                                <Modal.Title style={styles.center}>Have you verified your ported MSFS 2020 content and do you agree to its release on the MSFS 2024 Marketplace?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    <div style={styles.center} >
                                        <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                        <span>
                                        <Button style={{margin: 10}} onClick={() => onCarryOverSignedOffConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                        <Button style={{margin: 10}} onClick={() => onCarryOverSignedOffConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                                }

                            </Modal.Body>
                        </Modal>}
                        {updatingMetaData && <Modal show={true} backdrop={"static"} keyboard={false}>
                            <Modal.Header>
                                <Modal.Title style={styles.center}>Please Wait...</Modal.Title>
                            </Modal.Header>
                        </Modal>}
                    </aside>
                </div>
            </div>
        );
    }

    if(submission === undefined){
        return (
            <div className="container details" >
                <div className="row">
                    <main className="col-md-8 col-xs-12 ">
                        <Card text={"success"}>
                        <p> Sorry unable to find this offer :(</p>
                        </Card>
                    </main>
                        
                </div>
            </div>
        );
    }

    return (
        <div className="container details" >
            <div className="row">
                <main className="col-md-8 col-xs-12 ">
                    <Card text={"success"}>
                    </Card>
                </main>
                <aside className="col-md-4 col-xs-12 ">
                    <Card>
                        <h4>Submission Status</h4>
                    </Card>
                    <Card>
                        <h4>Publish Details</h4>
                    </Card>
                </aside>
            </div>
        </div>
    );

}

export default ViewOffer;