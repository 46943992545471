import axios from 'axios';
import { GetWebApiUrl } from '../Constants/utils';
import Auth from '../Auth/Auth';
import { ContentIngestionRequest, MarketplaceIngestRequest, MarketplaceSummary, MisPackageDetailsResponse, PackageDetails, PlatformDetails, ProductReleaseRequest, ProductReleaseResponse, PublishingTarget } from '../Models/AugerOffers/MarketplaceDetails';
import { uploadBrowserData } from './uploadBrowserData';
const API_URL = GetWebApiUrl();

export async function GetMarketplacePackages(auth: Auth): Promise<PackageDetails<MarketplaceSummary>[]> {
    let pageNumber = 0;
    const pageSize = 1000;
    let results:PackageDetails<MarketplaceSummary>[] = [];

    while (true) {
        const response = await GetMarketplaceSummary(auth, pageNumber, pageSize);

        results = results.concat(response);

        if (response.length === 0) {
            break;
        }

        pageNumber++;
    }

    return results;
}

export const GetMarketplaceSummary = (auth: Auth, pageNumber: number, pageSize: number): Promise<PackageDetails<MarketplaceSummary>[]> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/marketplace/summary?pageNumber=${pageNumber}&pageSize=${pageSize}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetMarketplacePackageDetails = (auth: Auth, packageName: string): Promise<PackageDetails<MisPackageDetailsResponse>> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/marketplace/details/${packageName}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetMarketplacePackageSummary = (auth: Auth, packageName: string): Promise<PackageDetails<MarketplaceSummary>> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/marketplace/summary/${packageName}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const DeleteMarketplacePackage = (auth: Auth, packageName: string, publisherId: string, developerId: string): Promise<void> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/marketplace/delete/${packageName}/${publisherId}/${developerId}`, undefined, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetContentPackages = (auth: Auth): Promise<PackageDetails<PlatformDetails>[]> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/content/summary`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetContentPackageDetails = (auth: Auth, packageName: string): Promise<PackageDetails<PlatformDetails>> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/content/details/${packageName}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const DeleteContentPackage = (auth: Auth, packageName: string, publishingTarget: PublishingTarget, publisherId: string, developerId: string): Promise<void> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
                const data = {
                    packageName: packageName,
                    publisherId: publisherId,
                    developerId: developerId,
                    publishingTarget: publishingTarget
                }

                axios.post(`${API_URL}/content/clean`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const UploadMediaAsset = (auth: Auth, file: File | Blob, fileName: string, id:string, packageName:string, developerId: string, publisherId: string, progressCallback?: any) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                /// alert("UploadFile " + fileName + "(1) size: " + file.size + " name(2): " + file.name )
                if (file.size < 1){
                    reject("file " + fileName + " cannot be uploaded to " + fileName + " (file size: " + file.size + ")")
                }
                // if (file.size > MAX_UPLOAD_SIZE) {
                //     reject("File " + file.name + " is larger than " + MAX_UPLOAD_SIZE / (1024 * 1024) + "MB!");
                // }

                const data = {
                    developerId: developerId,
                    filename: fileName,
                    packageName: packageName,
                    publisherId: publisherId,
                    submissionId: id
                }

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/marketplace/uploadUrls`, data, options).then(response => {
                    const url = response.data.url;

                    uploadBrowserData(url, file, function (ev: any) {
                        var progress = (ev.loadedBytes * 100) / file.size;
                        if (progressCallback) {
                            progressCallback(progress);
                        }
                    }, function () {
                        resolve(url);
                    }, function (error: any) {
                        reject(error);
                    });
                }).catch((error: string) => {
                    console.log("error:", error);
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const IngestMarketplaceProduct = (auth: Auth, data: MarketplaceIngestRequest): Promise<PackageDetails<MarketplaceSummary>> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/marketplace/ingest`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const IngestContentPackage = (auth: Auth, data: ContentIngestionRequest) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.put(`${API_URL}/content/ingest/start`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const TransferToPrerelease = (auth: Auth, data: ProductReleaseRequest, ignoreWorkItem?: boolean): Promise<ProductReleaseResponse> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.put(`${API_URL}/publish/prerelease${ignoreWorkItem ? "?ignoreWorkItem=true": ""}`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const TransferToRelease = (auth: Auth, data: ProductReleaseRequest): Promise<ProductReleaseResponse> => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.put(`${API_URL}/publish/release`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetPublisherIds = (auth: Auth) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/user/publishers`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}

export const GetDeveloperIds = (auth: Auth, publisherId: string) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then((accessToken: string) => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/user/publishers/${publisherId}/developers`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch((error: string) => {
                reject(error);
            });
        }
    );
}
