
import React, { Component } from 'react';

import {Card} from 'react-bootstrap';

class ReportPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    componentDidUpdate(prevProps, prevState) {

    }

    handleChange(event) {
        
    }

    resetToDefault() {
    }


    render() {
        const {
            color,
            headerTitle,
            headerSubTitle,
            panelBody,
            keyValue
        } = this.props;
        
        return (
            <Card text="warning" key={keyValue} style={{ borderColor: color }}>
                <Card.Header style={{ backgroundColor: color }}>
                    <Card.Title>
                        {headerTitle} <span style={{ float: "right" }}> {headerSubTitle} </span>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {panelBody}
                </Card.Body>
            </Card>
        );
    }
}

export {
    ReportPanel,
};