import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { center, formStyle } from '../../Constants/styles'
import { getDeveloperV2, getPartnerV2 } from '../../Api/WebApi'
import Auth from '../../Auth/Auth';
import PublisherList from './PublisherList';
import { Publisher } from '../../Models/Publisher';
import { Developer } from '../../Models/Developer';

interface Props {
    auth: Auth;
}

function PublisherBrowser(props: Props) {
    const [publishers, setPublishers] = useState<Publisher[]>([]);
    const [developers, setDevelopers] = useState<Developer[]>([]);

    useEffect(() => {
        document.title = "Publisher Browser | Marketplace Content Portal";

        const fetchPublisherAndDeveloperData = async () => {
            const publishers: Publisher[] = await getPartnerV2(props.auth);

            if (publishers.length === 0) {
                setDevelopers([]);
            } else {
                setPublishers(publishers);
            }

            const developers: Developer[] = await getDeveloperV2(props.auth);

            if (developers.length === 0) {
                setDevelopers([]);
            } else {
                setDevelopers(developers);
            }
        }

        fetchPublisherAndDeveloperData();
    }, [props.auth]);

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card style={formStyle}>
                        <h1 style={center}>Publishers</h1>
                        <PublisherList partners={publishers} developers={developers} auth={props.auth} />
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default PublisherBrowser;