import React from 'react';
import { Card } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { MarketplaceSummary, PackageDetails, PlatformDetails } from '../Models/AugerOffers/MarketplaceDetails';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';
import ReactTable from '../ReactTable';

interface Props {
    auth: Auth,
    isNew: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackageSummary: PackageDetails<MarketplaceSummary>,
    marketplacePackageSummaries: PackageDetails<MarketplaceSummary>[],
    contentPackage: ContentPackage,
    contentPackagesDetails: PackageDetails<PlatformDetails>[]
}

export function MarketplaceProductDetails(props: Props) {
    var columnStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }

    const columns = [
        {
            id: "platform",
            accessor: (row: any) => row.platform,
            maxWidth: 20,
            style: columnStyle
        },
        {
            Header: 'Draft',
            columns: [
                {
                    id: "draftVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.draft.version,
                    maxWidth: 10,
                    style: columnStyle
                },
                {
                    id: "draftSubmissionId",
                    Header: 'Submission Id',
                    accessor: (row: any) => row.draft.submissionId,
                    // maxWidth: 30,
                    style: columnStyle
                },
                {
                    id: "draftStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.draft.status,
                    maxWidth: 15,
                    style: columnStyle
                },
                {
                    id: "draftGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.draft.type,
                    maxWidth: 15,
                    style: columnStyle
                }
            ]
        },
        {
            Header: 'Prerelease',
            columns: [
                {
                    id: "prereleaseVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.prerelease.version,
                    maxWidth: 10,
                    style: columnStyle
                },
                {
                    id: "prereleaseSubmissionId",
                    Header: 'Submission Id',
                    accessor: (row: any) => row.prerelease.submissionId,
                    // maxWidth: 30,
                    style: columnStyle
                },
                {
                    id: "prereleaseStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.prerelease.status,
                    maxWidth: 15,
                    style: columnStyle
                },
                {
                    id: "prereleaseGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.prerelease.type,
                    maxWidth: 15,
                    style: columnStyle
                }
            ]
        },
        {
            Header: 'Release',
            columns: [
                {
                    id: "releaseVersion",
                    Header: 'Version',
                    accessor: (row: any) => row.release.version,
                    maxWidth: 10,
                    style: columnStyle
                },
                {
                    id: "releaseSubmissionId",
                    Header: 'Submission Id',
                    accessor: (row: any) => row.release.submissionId,
                    // maxWidth: 30,
                    style: columnStyle
                },
                {
                    id: "releaseStatus",
                    Header: 'Status',
                    accessor: (row: any) => row.release.status,
                    maxWidth: 15,
                    style: columnStyle
                },
                {
                    id: "releaseGameType",
                    Header: 'Type',
                    accessor: (row: any) => row.release.type,
                    maxWidth: 15,
                    style: columnStyle
                }
            ]
        }];

    const tableData = [
        {
            platform: "Marketplace",
            draft: {
                version: props.marketplacePackageSummary.draft?.ingestionVersion ?? "",
                submissionId: props.marketplacePackageSummary.draft?.submissionId ?? "",
                status: props.marketplacePackageSummary.draft?.ingestionStatus ?? "",
                type: props.marketplacePackageSummary.draft?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.draft?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            prerelease: {
                version: props.marketplacePackageSummary.prerelease?.ingestionVersion ?? "",
                submissionId: props.marketplacePackageSummary.prerelease?.submissionId ?? "",
                status: props.marketplacePackageSummary.prerelease?.ingestionStatus ?? "",
                type: props.marketplacePackageSummary.prerelease?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.prerelease?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            release: {
                version: props.marketplacePackageSummary.release?.ingestionVersion ?? "",
                submissionId: props.marketplacePackageSummary.release?.submissionId ?? "",
                status: props.marketplacePackageSummary.release?.ingestionStatus ?? "",
                type: props.marketplacePackageSummary.release?.packageName?.startsWith("fs20") ? "2020" : props.marketplacePackageSummary.release?.packageName?.startsWith("fs24") ? "2024" : ""
            }
        },
        {
            platform: "PC",
            draft: {
                version: props.contentPackage.draft.value?.PC?.version ?? "",
                submissionId: props.contentPackage.draft.value?.PC?.submissionId ?? "",
                status: props.contentPackage.draft.value?.PC?.version ? (props.contentPackage.draft.value?.PC?.isIngestionError ? "Error" : props.contentPackage.draft.value?.PC?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.draft?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.draft?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            prerelease: {
                version: props.contentPackage.prerelease.value?.PC?.version ?? "",
                submissionId: props.contentPackage.prerelease.value?.PC?.submissionId ?? "",
                status: props.contentPackage.prerelease.value?.PC?.version ? (props.contentPackage.prerelease.value?.PC?.isIngestionError ? "Error" : props.contentPackage.prerelease.value?.PC?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.prerelease?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.prerelease?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            release: {
                version: props.contentPackage.release.value?.PC?.version ?? "",
                submissionId: props.contentPackage.release.value?.PC?.submissionId ?? "",
                status: props.contentPackage.release.value?.PC?.version ? (props.contentPackage.release.value?.PC?.isIngestionError ? "Error" : props.contentPackage.release.value?.PC?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.release?.value?.PC?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.release?.value?.PC?.packageName?.startsWith("fs24") ? "2024" : ""
            }
        },
        {
            platform: "Xbox",
            draft: {
                version: props.contentPackage.draft.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.draft.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.draft.value?.Xbox?.version ? (props.contentPackage.draft.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.draft.value?.Xbox?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.draft?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.draft?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            prerelease: {
                version: props.contentPackage.prerelease.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.prerelease.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.prerelease.value?.Xbox?.version ? (props.contentPackage.prerelease.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.prerelease.value?.Xbox?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.prerelease?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.prerelease?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            },
            release: {
                version: props.contentPackage.release.value?.Xbox?.version ?? "",
                submissionId: props.contentPackage.release.value?.Xbox?.submissionId ?? "",
                status: props.contentPackage.release.value?.Xbox?.version ? (props.contentPackage.release.value?.Xbox?.isIngestionError ? "Error" : props.contentPackage.release.value?.Xbox?.isIngested ? "Live" : "Pending") : "",
                type: props.contentPackage.release?.value?.Xbox?.packageName?.startsWith("fs20") ? "2020" : props.contentPackage.release?.value?.Xbox?.packageName?.startsWith("fs24") ? "2024" : ""
            }
        }
    ];

    return (
        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Card style={{margin: 20}}>
                    <div>
                        <ReactTable
                            style={{clear:'right'}}
                            data={tableData}
                            columns={columns}
                            loading={!props.contentPackage || !props.marketplacePackageSummary}
                            minRows={1}
                            noDataText = {"No data!"}
                            defaultSorted={ [] }
                            defaultPageSize={10}
                            disableSortBy={true}
                            className="-striped -highlight"
                            hideSearch={true}
                            hidePagination={true} />
                    </div>
                </Card>
            </main>
    );
}
