import * as routes from '../Constants/routes'
import withRouter from '../withRouter';
import React, { CSSProperties, Component } from 'react';
import AugerOfferFactory from '../Factories/AugerOfferFactory';

import { Button, Modal, Card } from 'react-bootstrap';
import { UpdateUploader } from './UpdateUploader';
import { GetSubmission, GetDefaultModel } from '../Api/WebApi'
import { center, formStyle } from '../Constants/styles'
import { VSO_STATES, WORK_FLOW_TYPES, AUGER_OFFER_TYPES, VALIDATION_DECISIONS } from '../Constants/enums';
import Auth from '../Auth/Auth';
import { NavigateFunction } from 'react-router';
import { DurableOfferModel } from '../Models/AugerOffers/DurableOfferModel';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import * as styles from '../Constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';

interface Props {
    auth: Auth,
    navigate: NavigateFunction
    params: {
        offerType: string,
        offerId: string
    }
}

type ValidationResult = {
    prop: string,
    confirmed: boolean
    message: string | undefined,
}

interface State {
    error: boolean,
    auth: Auth,
    updateInfo: any,
    updateInfoLinked: any,
    validateOffer: boolean,
    submission: DurableOfferModel | null | undefined,
    originalValues: DurableOfferModel | null | undefined
    validationResults?: { passed: boolean, results: ValidationResult[] },
    validationResult?: ValidationResult
}

class CreateUpdate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.initialState;
        this.processFormInfo = this.processFormInfo.bind(this);
        this.finishUpload = this.finishUpload.bind(this);
        this.onValidationResultConfirmation = this.onValidationResultConfirmation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    get initialState(): State {
        return {
            error: false,
            auth: this.props.auth,
            updateInfo: null,
            updateInfoLinked: null,
            validateOffer: false,
            submission: null,
            originalValues: null
        }
    }

    async componentDidMount() {
        let submission = await this.getLinkedSubmissions();
        var originalValues = await this.getLinkedSubmissions();

        if (submission) {
            document.title = (submission.cardState.value === VSO_STATES.RELEASED ? "Update: " : "Resubmit: ") + submission.offerTitle.value + " | Marketplace Content Portal";
        }
        
        this.setState(() => ({
            submission,
            originalValues,
        }));
    }

    async getSubmission(auth: Auth, offerId: string) {
        let result = await GetSubmission(auth, offerId);

        result.auth = auth;
        result.binaryLocation = null;

        let submission = AugerOfferFactory.build(result.offerType, result) as DurableOfferModel;

        if (submission?.cardState.value === 'Closed') {
            submission.workflowType.value = WORK_FLOW_TYPES.OFFER;
            // default to showing full update.  user can change later if desired.
            // The Closed state indicates that an offer is being updated so allow the user to choose
            // any available workflowType.  once the first update is re-submitted and the workflow
            // has begun the user must complete the workflow before starting another.  this prevents
            // starting a workflow that does not include a binary and the editing later adding a binary 
            // but having bypassed all internal validation gates
        }

        return submission;
    }

    async getLinkedSubmissions() {
        let submission = await this.getSubmission(this.state.auth, this.props.params.offerId);

        if (submission?.tempLinkedItem.value) {
            submission.linkedSubmission = await this.getSubmission(this.state.auth, submission.tempLinkedItem.value as string);

            if (submission.linkedSubmission) {
                submission.linkedSubmission.linkedSubmission = submission;
            }
        } else if (submission) {
            var result = await GetDefaultModel(this.state.auth, AUGER_OFFER_TYPES.DURABLE_OFFER);

            result.auth = this.state.auth;

            submission.linkedSubmission = AugerOfferFactory.build(AUGER_OFFER_TYPES.DURABLE_OFFER, result) as DurableOfferModel;

            if (submission.linkedSubmission) {
                submission.linkedSubmission.linkedSubmission = submission;
                submission.linkedSubmission.workItemPlatform.value = submission.workItemPlatform.value === 'PC' ? 'Xbox' : 'PC';
            }
        }

        return submission;
    }

    onValidationResultConfirmation(confirmed: boolean) {
        if (confirmed && this.state.validationResult) {
            this.state.validationResult.confirmed = true;

            const nextValidationResult = this.state.validationResults?.results.find(r => r.confirmed === false);

            this.setState({
                validationResult: nextValidationResult
            });

            if (!nextValidationResult) {
                let tags = this.state.validationResults?.results.map(result => result.prop.replace(/([a-z])([A-Z])/g, '$1 $2') + ' Changed').join(',');
                
                this.setState({
                    validationResults: undefined
                });

                this.submitUpdate(tags);
            }
        } else {
            this.setState({
                validationResults: undefined,
                validationResult: undefined
            })
        }
    }

    onSubmit(results: { passed: boolean, results: {prop: string, message: string | undefined}[] }) {
        const validationResults: ValidationResult[] = results.results.map(result => ({ prop: result.prop, confirmed:false, message: result.message }));
        const validationResult = validationResults?.find(result => result.confirmed === false);

        if (validationResult) {
            this.setState({
                validationResults: { passed: results.passed, results: validationResults },
                validationResult: validationResult
            });
        } else {
            this.submitUpdate();
        }
    }

    submitUpdate(tags?: string) {
        let updateSubmission = (this.state.submission?.ingestionPlatforms?.value as string)?.includes(this.state.submission?.workItemPlatform.value as string);
        let updateInfo = this.state.submission?.exportForSubmission(updateSubmission, true, tags);
        let updateInfoLinked: any = null;
        console.log("updateInfo:", updateInfo);

        if (this.state.submission?.linkedSubmission) {
            let updateLinkedSubmission = (this.state.submission?.ingestionPlatforms?.value as string)?.includes(this.state.submission?.linkedSubmission?.workItemPlatform.value as string);

            if (updateLinkedSubmission) {
                updateInfoLinked = this.state.submission.linkedSubmission.exportForSubmission(updateLinkedSubmission, true, tags);
                console.log("updateInfoLinked:", updateInfoLinked);
            }
        }

        this.setState({
            updateInfo: updateInfo,
            updateInfoLinked: updateInfoLinked
        });
    }

    onCancel() {
        const browser = routes.CONTENT_BROWSER_ROOT;
        console.log("Cancelled");
        this.props.navigate(browser + this.state.submission?.offerType.value);
    }

    finishUpload(uploadStatus: string) {
        const browser = routes.CONTENT_BROWSER_ROOT;
        console.log("uploadStatus", uploadStatus);
        this.props.navigate(browser + this.state.submission?.offerType.value);
    }

    processFormInfo<T extends MarketplaceItem>(offer: T | undefined, propertyName: keyof T, propertyValue?: any) { 
        if (propertyValue == null) {
            return;
        }
        
        const {
            submission
        } = this.state;

        if (submission) {
            if (propertyName as string === 'ingestionPlatforms') {
                const propertyValueAsString = propertyValue as string;

                propertyValue = (propertyValueAsString.toLowerCase().includes('pc') && propertyValueAsString.toLowerCase().includes('xbox') ? 'PC & Xbox' : propertyValueAsString.includes('PC') ? 'PC Only' : 'Xbox Only');
                
                submission.ingestionPlatforms.value = propertyValue;

                if (propertyValue === 'PC & Xbox' && submission.linkedSubmission) {
                    submission.linkedSubmission.ingestionPlatforms.value = propertyValue;
                }

                this.setState(() => ({ submission: submission}));
            } else {
                if (propertyName as string === 'validationResult') {
                    if (propertyValue.decision !== VALIDATION_DECISIONS.PENDING) {
                        this.setState(() => ({ validateOffer: true }));
                    }
                }
                
                var setSubmission = submission.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);

                if (submission.linkedSubmission) {
                    setSubmission.linkedSubmission = submission.linkedSubmission.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
                }

                this.setState(() => ({ submission: setSubmission}));
            }
        }
    }

    render() {
        const {
            error,
            submission,
            originalValues,
            auth,
            updateInfo,
            updateInfoLinked,
            validateOffer,
            validationResult
        } = this.state;

        if(submission != null){

            if(!submission.isOfferUpdatable()){
                return (
                    <div className="container create-new">
                        <div className="row">
                            <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <Card style={formStyle}>
                                    <h1 style={center}> Sorry this offer is not updateable currently!</h1>
                                </Card>
                            </main>
                        </div>
                    </div>
                );
            }

            var submissionFormFields = submission.getSubmissionFormFields(this.processFormInfo, false, validateOffer, true, submission.workItemPlatform.value !== 'Xbox', submission.workItemPlatform.value !== 'PC');
            const workflowType = submission.workflowType.value;
            const validationResults = submission.validateOffer(true, submission.workItemPlatform.value === 'PC', submission.workItemPlatform.value === 'Xbox', originalValues);
            var validOffer = (workflowType 
                           && workflowType !== '' /* must always select a workflowType regardless */
                           && validationResults.passed) 
                           ? true : false;

            return (
                <div className="container create-new">
                    <div className="row">
                        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {validationResult && <Modal show={true} onHide={() => this.onValidationResultConfirmation(false)} backdrop={"static"} keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title style={styles.center}>Are You Sure?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {
                                        <div style={styles.center} >
                                            <h3 style={styles.warningText as CSSProperties}>{validationResult.message}</h3>
                                            <span>
                                            <Button style={{margin: 10}} onClick={() => this.onValidationResultConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{padding: 10}} />CANCEL</Button>
                                            <Button style={{margin: 10}} onClick={() => this.onValidationResultConfirmation(true)}>OK<FontAwesomeIcon icon={faForward} style={{padding: 10}} /></Button>
                                            </span>
                                        </div>
                                    }

                                </Modal.Body>
                            </Modal>}
                            <Card style={formStyle}>
                                {error && <h3 style={center}> Error: {error} </h3>}
                                <h1 style={center}> {(submission.cardState.value === VSO_STATES.RELEASED ? "Update: " : "Resubmit: ")} {submission.offerTitle.value as string} </h1>
                                <div style={center}><em>Note: These fields are automatically extracted from the package to be submitted. If any fields are incorrect, please update the package with the correct values.</em></div>
                                {
                                    updateInfo &&
                                    <UpdateUploader auth={auth} updateInfo={updateInfo} updateInfoLinked={updateInfoLinked} callbackFromParent={this.finishUpload} />
                                }
                                {submissionFormFields}
                                <Button style={{ marginTop: 20 }} variant="primary"
                                    onClick={event => this.onSubmit(validationResults)}
                                    disabled={!validOffer}>{"SUBMIT"}</Button>
                                <Button style={{ marginTop: 20 }} variant="default"
                                    onClick={event => this.onCancel()}>{"CANCEL"}</Button>
                            </Card>
                        </main>
                    </div>
                </div>
            );
        }
        else{
            return (
                <div className="container create-new">
                    <div className="row">
                        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Card style={formStyle}>
                                {error && <h3 style={center}> Error: {error} </h3>}
                            </Card>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(CreateUpdate);