
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { center } from '../../Constants/styles';

import { VALIDATION_DECISION_INFO, VALIDATION_DECISIONS } from '../../Constants/enums';

import { GetValidationReportSummary } from '../../Api/WebApi';

import loadingsmall from '../../Images/loadingsmall.svg';

import ReactTable from "../../ReactTable";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ValidationReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reports: null,
            error: null,
            auth: this.props.auth,
            hasPending: true,
            isMounted: false,
            cancelablePromise: null,
            fillReport: null
        };

        this.parseReports = this.parseReports.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.hasPending !== this.props.hasPending){
            this.setState(() => ({ hasPending: this.props.hasPending }));
        }
        if(prevProps.fillReport !== this.props.fillReport){
            console.log("made it to val reports!", this.props.fillReport);
            this.setState(() => ({ fillReport: this.props.fillReport }));
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        this.setState(() => ({ isMounted: false }));
        if (this.cancelablePromise != null) {
            this.cancelablePromise.cancel();
        }


    }

    componentDidMount() {
        this.timer = setInterval(() => this.getReports(), 3000);
        this.setState(() => ({ isMounted: true }));
        this.cancelablePromise = null;

    }

    getReports() {
        const {
            auth,
            hasPending
        } = this.state;

        if (hasPending === true && this.cancelablePromise === null) {
            const nextCancelablePromise = GetValidationReportSummary(auth);

            nextCancelablePromise
                .promise
                .then((result) => {

                    this.setState(() => ({ reports: result }));
                    this.props.callbackFromParent(result);

                    var hasPending = false;
                    for (var i in result) {
                        if (result[i].decision === VALIDATION_DECISIONS.PENDING) {
                            hasPending = true;
                        }
                    }

                    console.log("setting fill rpeort to null");
                    this.setState(() => ({ fillReport: null }));
                    this.setState(() => ({ hasPending: hasPending }));
                    this.cancelablePromise = null;

                }).catch((error) => {
                    console.log("errormaybecancel", error);
                });


            this.cancelablePromise = nextCancelablePromise;

        }


    }

    parseReports(reports) {

        var filteredReports = [];
        for(var i = 0; i < reports.length; i++){
            var found = false;
            for(var j = 0; j < filteredReports.length; j++){
                if(reports[i].submissionId === filteredReports[j].submissionId){
                    found = true;
                }
            }
            if(!found){
                filteredReports.push(reports[i]);
            }
        }

        var reportData = filteredReports.map(function (item) {

            var symbol = (item.decision === VALIDATION_DECISIONS.PENDING) ? <img src={loadingsmall} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="loading" /> : <FontAwesomeIcon style={{ color: VALIDATION_DECISION_INFO[item.decision].color, display: "block", textAlign: "center" }} icon={VALIDATION_DECISION_INFO[item.decision].symbol} />;
            var name = VALIDATION_DECISION_INFO[item.decision].readable;
            var isViewable = VALIDATION_DECISION_INFO[item.decision].viewable;

            var linkPath = "/viewValidationReport/" + item.submissionId + "/"

            return (
                {
                    decision: name,
                    filename: item.contentName,
                    time: item.validationTime,
                    buttonInfo: {
                        linkPath: linkPath,
                        disabled: !isViewable
                    },
                    symbol: symbol
                }
            );

        }, this);

        return reportData;
    }
    

    render() {
        const {
            error,
            reports
        } = this.state;

        var options = { hour12: true, year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: '',
                accessor: 'symbol', // String-based value accessors!
                maxWidth: 30,
                sortable: false,
                filterable: false,
                style: columnStyle
            },
            {
                Header: 'Decision',
                accessor: 'decision', // String-based value accessors!
                maxWidth: 150,
                style: columnStyle
            },
            {
                Header: 'File Name',
                accessor: 'filename',
                style: columnStyle
            },
            {
                Header: 'Submission Time', // Required because our accessor is not a string
                accessor: 'time',
                maxWidth: 200,
                filterable: false,
                style: columnStyle,
                Cell: row => (
                    new Date(row.value).toLocaleDateString('en-US', options)
                )
            },
            {
                Header: '',
                accessor: 'buttonInfo',
                maxWidth: 80,
                sortable: false,
                filterable: false,
                Cell: row => (
                    <Link to={row.value.linkPath} >
                        <Button id="bootstrap-overrides" variant="success" size="sm" block
                            disabled={row.value.disabled}> View </Button>
                    </Link>
                )
            }]

        var data = [];
        var loading = true;
        var pageSize = 5;
        if (reports !== null && reports !== undefined) {
            var newReports = reports;
            if(this.state.fillReport){
                newReports.push(this.state.fillReport);
            }
            data = this.parseReports(newReports);
            loading = false;
            pageSize = data.length;
        }

        return (
            <div>
                {
                    error &&
                    <p> {error} </p>
                }

                <h1 style={center}> Validation Results </h1>
                <div className="offerList">
                    <ReactTable
                        data={data}
                        columns={columns}
                        loading={loading}
                        defaultSorted={
                            [
                                {
                                    id: "time",
                                    desc: true
                                }
                            ]
                        }
                        pageSize={pageSize}
                        defaultPageSize={pageSize}
                        defaultFilterMethod={ (filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
                            }}
                        showPagination={false}
                        filterable={false}
                        className="-striped -highlight"
                    />
                </div>
            </div>

        );
    }
}

export {
    ValidationReports,
};