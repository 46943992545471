import { msalApp, requiresInteraction } from "./auth-utils";
import { CheckUserAuth, getDeveloperV2, getPartnerV2 } from '../Api/WebApi';
import version from '../version.json';
import { AccountInfo } from "@azure/msal-common";
import { UserAccessLevel } from "../Models/User";
import { GetDeveloperIds } from "../Api/RomaWebApi";
import { get } from "http";

export default class Auth {
  constructor() {
    this.onSignIn = this.onSignIn.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.getPublishers = this.getPublishers.bind(this);
    this.isApprovedCreator = this.isApprovedCreator.bind(this);
    this.isSignedInWithMSA = this.isSignedInWithMSA.bind(this);
  }

  async acquireToken(account: AccountInfo, scopes: string[]): Promise<string> {
      const authenticationResult = await msalApp.acquireTokenSilent({account: account, scopes: scopes}).catch(async error => {
          // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
          // due to consent or interaction required ONLY
          if (requiresInteraction(error.errorCode)) {
              return await msalApp.acquireTokenPopup({account: account, scopes: scopes});
          } else {
              console.error('Non-interactive error:', error);
              throw error;
          }
      });

      msalApp.setActiveAccount(authenticationResult.account);

      return authenticationResult.accessToken;
  }

  async onSignIn() {
    console.log("onSignIn() called.");

    const authenticationResult = await msalApp.loginPopup({
      scopes: [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ],
      prompt: 'select_account'
    }).catch(error => {
      console.log("onSignIn() error:", error);
      throw error;
    });

    msalApp.setActiveAccount(authenticationResult.account);

    if (authenticationResult) {
      let result = await CheckUserAuth(authenticationResult.accessToken);
      
      if (result) {
        try {
          const publishers = await getPartnerV2(this);

          localStorage.setItem('publishers', JSON.stringify(publishers));
        } catch {
          localStorage.setItem('publishers', JSON.stringify([]));
        }
      
        try {
          const developers = await getDeveloperV2(this);
            
          localStorage.setItem('developers', JSON.stringify(developers));
        } catch {
          localStorage.setItem('developers', JSON.stringify([]));
        }

        localStorage.setItem('api_auth', "true");
        localStorage.setItem('name', result.name);
        localStorage.setItem('enableBetaFeatures', result.enableBetaFeatures ? "true" : "false")
        localStorage.setItem('accessLevel', result.accessLevel);
        localStorage.setItem('lastVersion', version.version);

        console.log("onSignIn() successful.");

        return true;
      }
    }

    console.log("onSignIn() failed.");

    return false;
  }

  async login() {
    console.log("login() called.");

    localStorage.removeItem('publishers');
    localStorage.removeItem('developers');
    localStorage.removeItem('api_auth');
    localStorage.removeItem('name');
    localStorage.removeItem('enableBetaFeatures')
    localStorage.removeItem('accessLevel');
    localStorage.removeItem('lastVersion');

    return await this.onSignIn();
  }

  isApprovedCreator() {
    const api_auth = localStorage.getItem('api_auth');

    if (api_auth === "true") {
      return true;
    }

    return false;
  }

  async getAccessToken(): Promise<string> {
    console.log("getAccessToken() called.");
    
    const myAccounts = msalApp.getAllAccounts();
    const tokenResponse = await this.acquireToken(
      myAccounts[0],
      [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ]
    ).catch(error => {
      console.log("getAccessToken() error:", error);
      throw error;
    });

    return tokenResponse;
  }

  getUserName() {
    const creatorUser = localStorage.getItem('name');
    if (!creatorUser) {
      return "";
    }
    return creatorUser;
  }

  getPublishers() {
    return JSON.parse(localStorage.getItem('publishers') ?? "[]");
  }

  getDevelopers() {
    return JSON.parse(localStorage.getItem('developers') ?? "[]");
  }

  getEnableBetaFeatures() {
    const creator_club = localStorage.getItem('enableBetaFeatures');

    if (creator_club === "true") {
      return true;
    }

    return false;
  }

  isAdministrator() {
    const creatorRole = localStorage.getItem('accessLevel');

    return creatorRole === UserAccessLevel.Administrator;
  }

  isInternal() {
    const creatorRole = localStorage.getItem('accessLevel');

    return creatorRole === UserAccessLevel.Administrator || creatorRole === UserAccessLevel.Full;
  }

  isExternal() {
    const creatorRole = localStorage.getItem('accessLevel');

    return creatorRole !== UserAccessLevel.Administrator && creatorRole !== UserAccessLevel.Full;
  }

  getLastVersion() {
    return localStorage.getItem('lastVersion');
  }

  logout() {
    localStorage.removeItem('publishers');
    localStorage.removeItem('developers');
    localStorage.removeItem('api_auth');
    localStorage.removeItem('name');
    localStorage.removeItem('enableBetaFeatures')
    localStorage.removeItem('accessLevel');
    localStorage.removeItem('lastVersion');

    msalApp.logoutRedirect();
  }

  isSignedInWithMSA() {
    return msalApp.getActiveAccount() !== null;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    var isApprovedCreator = this.isApprovedCreator();
    var isCurrent = this.isSignedInWithMSA();
    if (isApprovedCreator && isCurrent) {
      return true;
    } else {
      return false;
    }
  }
}
