
import React, { Component } from 'react';

import { GetTimelessDate } from '../../Constants/utils';

import { Link } from 'react-router-dom';

class TestReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };
    }

    componentDidUpdate(prevProps, prevState) {

    }

    parseReportsOfType(reports, type) {
        var newReports = [];



        reports.forEach(element => {
            if (element.type === type) {
                newReports.push(element);
            }
        });

        var sortedReports = newReports.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA < dateB)
        });

        if (sortedReports.length > 0) {
            sortedReports.unshift("empty");
        }

        return sortedReports;
    }

    reportListDisplay(reportList, type) {
        var reportData = reportList.map(function (item, index) {
            var linkText = "/viewReport/" + item.id + "/";
            if (index === 0) {
                return (
                    (index === 0) &&
                    <tr key={item.id + " title"}>
                        <th htmlFor={item.version}>{type}:&nbsp;</th>
                        <td htmlFor={item.version}>&nbsp;</td>
                    </tr>

                );
            } else {
                return (
                    <tr key={item.id}>
                        <th id={"link"} className="reportcolumn left">
                        {item.result}
                        </th>
                        <td id={"link"} className="reportcolumn right">
                        <Link to={linkText}>{item.version} Report - {GetTimelessDate(item.date)} </Link>
                        </td>
                    </tr>
                );
            }
        });

        return (

            <table className="table-condensed table table-hover reporttable"><tbody>{reportData}</tbody></table>
        );
    }

    render() {

        if (this.state.value !== null && this.state.value !== undefined) {
            var QAReports = this.parseReportsOfType(this.state.value, "QA");
            var CRReports = this.parseReportsOfType(this.state.value, "CR");

            var QARows = this.reportListDisplay(QAReports, "Certification");
            var CRRows = this.reportListDisplay(CRReports, "Content Review");

            return (
                <div>
                    {CRRows}
                    {QARows}
                </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }
}

export {
    TestReports,
};