import React, { Component } from 'react';


import { Button, Card } from 'react-bootstrap';

import { formStyle, center } from '../Constants/styles'
import { FileUpload } from '../Components/Forms/FileUpload';
import { ValidationReports } from '../Components/DisplayInfo/ValidationReports';
import { ResolveFormValidity } from '../Constants/utils';
import { ValidationUploader } from '../Components/ValidationUploader';
import { VALIDATION_DECISIONS, UPDATE_STATUS } from '../Constants/enums';

import { UploaderModal } from '../Components/UploaderModal';


class Validator extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.processFormInfo = this.processFormInfo.bind(this);
        this.finishUpload = this.finishUpload.bind(this);
        this.reportSummaryResults = this.reportSummaryResults.bind(this);
        this.progressCallback = this.progressCallback.bind(this);
        this.statusCallback = this.statusCallback.bind(this);
    }


    componentDidMount() {
        document.title = "Validator | Marketplace Content Portal";
    }

    get initialState() {
        return {
            error: false,
            auth: this.props.auth,
            formState: this.initialFormState,
            upload: false,
            updateInfo: null,
            hasPending: true,
            uploadProgress: 0,
            validationId: null,
            overallStatus: UPDATE_STATUS.notstarted,
            fillReport: null
        }
    }

    get initialFormState() {
        return {
            FileUpload: { value: null, state: "success" },
        }
    }

    progressCallback(percentage){
        this.setState(() => ({ uploadProgress: percentage }));
    }

    statusCallback(newStatus, id){
        this.setState(() => ({ overallStatus: newStatus }));
        this.setState(() => ({ validationId: id }));
    }


    processFormInfo(name, value, state) {
        //console.log("process form info", name, value, state, this.state); 
        var newFormState = { ...this.state.formState }
        newFormState[name].value = value;
        newFormState[name].state = state;

        this.setState(() => ({ formState: newFormState }));   
        this.setState(() => ({ overallStatus: UPDATE_STATUS.pending }));   
    }

    finishUpload(uploadStatus) {
        console.log("uploadStatus", uploadStatus);

        var newFillReport = {
            decision: VALIDATION_DECISIONS.PENDING,
            contentName: this.state.formState.FileUpload.value.name,
            validationTime: Date.now(),
            submissionId: this.state.validationId
        }

        this.setState(() => ({ ...this.initialState }));
        this.setState(() => ({ fillReport:  newFillReport}));  
         
        //this.props.history.push(routes.CONTENT_BROWSER);

        //window.location.reload();
    }

    reportSummaryResults(results) {
        console.log("reportSummaryResults", results);
        var hasPending = false;
        for (var i in results) {
            if (results[i].decision === VALIDATION_DECISIONS.PENDING) {
                hasPending = true;
            }
        }
        if (hasPending === false) {
            this.setState(() => ({ hasPending: hasPending }));
        }
    }

    onSubmit() {

        this.setState(() => ({ upload: true }));

    }

    render() {
        const {
            formState,
            auth,
            upload,
            hasPending,
            overallStatus,
            uploadProgress,
            fillReport
        } = this.state;

        //console.log("submissions", submissions); test

        var isFormValid = ResolveFormValidity(this.state.formState);
        var errorMessage = "Submit For Validation";
        var validatorClassName = ""
        /* 
        if (hasPending) {
            isFormValid = false
            errorMessage = "You must wait until all pending packs have been validated before submitting again."
            validatorClassName = "validatorsubmit";
        }*/

        return (
            <main className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Card style={formStyle}>
                            {
                                <div>
                                    <h1 style={center}> Validator </h1>
                                    <FileUpload value={formState.FileUpload.value} callbackFromParent={this.processFormInfo} hasValidationPrompt={false} />
                                    <Button style={{ marginTop: 20 }} variant="primary" className={validatorClassName}
                                        block
                                        onClick={event => this.onSubmit()}
                                        disabled={!isFormValid}>{errorMessage}</Button>
                                </div>
                            }
                        </Card>
                        <Card style={formStyle}>
                            {
                                upload &&
                                <div>
                                    <ValidationUploader packType={""}  progressCallback={this.progressCallback} statusCallback={this.statusCallback}  auth={auth} binaryFile={formState.FileUpload.value}/>
                                    <UploaderModal overallStatus={overallStatus} uploadProgress={uploadProgress} returnButtonText="Return to Validator" callbackFromParent={this.finishUpload}/>
                                </div>
                            }
                            <ValidationReports fillReport={fillReport} hasPending={hasPending} history={this.props.history} auth={auth} callbackFromParent={this.reportSummaryResults} />
                        </Card>

                    </div>
                </div>
            </main>
        );
    }
}


export default Validator;