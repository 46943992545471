import React, { Component } from 'react';

import { Button, Modal, ProgressBar} from 'react-bootstrap';

import { UPDATE_STATUS } from '../Constants/enums';

import * as styles from '../Constants/styles';

class UploaderModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            overallStatus:      this.props.overallStatus,
            returnButtonText :  this.props.returnButtonText,
            uploadProgress :    this.props.uploadProgress,
            show:               true,
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleButton(overallStatus) {
        console.log("handleButton", overallStatus)
        this.props.callbackFromParent(overallStatus)
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        const {
            overallStatus,
            uploadProgress,
            returnButtonText
        } = this.props;

        const {
            show,
        } = this.state;

        var modalTitle = "Submission in progress...."
        if (overallStatus === UPDATE_STATUS.success) {
            modalTitle = "Submission Complete!"
        }
        if (overallStatus === UPDATE_STATUS.failed) {
            modalTitle = "Submission Failed!"
        }

        return (
            <Modal show={show} onHide={this.handleClose} backdrop={"static"} keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={styles.center}>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (overallStatus === UPDATE_STATUS.pending) && <div style={styles.center}>
                            <ProgressBar variant="success" active now={uploadProgress} label={`${uploadProgress}%`} />
                        </div>
                    }

                    {

                        (overallStatus === UPDATE_STATUS.success) &&
                        <div>
                            <div style={styles.center} >
                                <Button onClick={() => this.handleButton(overallStatus)}>{returnButtonText}</Button>
                            </div>
                        </div>
                    }
                    {
                        (overallStatus === UPDATE_STATUS.failed) &&
                        <div style={styles.center} >
                            <h3 style={styles.errorText}>Submission failed! Please contact your partner manager!</h3>
                            <Button onClick={() => this.handleButton(overallStatus)}>{returnButtonText}</Button>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        )
    }
}

export {
    UploaderModal,
}