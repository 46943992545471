import React, { useEffect, useReducer, useState } from 'react';
import { Button } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { GetContentPackageDetails, GetDeveloperIds, GetMarketplacePackageDetails, IngestMarketplaceProduct, UploadMediaAsset } from '../Api/RomaWebApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { MarketplaceProductThumbnails } from './MarketplaceProductThumbnails';
import { getLatestContentPackageDetailsResponse, getLatestMarketplacePackageDetailsResponse, getPackageNameNoPrefix, MarketplaceSummary, MisMediaAsset, MisPackageDetailsResponse, PackageDetails, PlatformDetails } from '../Models/AugerOffers/MarketplaceDetails';
import { MarketplaceProductRentals } from './MarketplaceProductRentals';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

interface Props {
    auth: Auth,
    isNew: boolean,
    validate: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackageSummaries: PackageDetails<MarketplaceSummary>[],
    contentPackagesDetails: PackageDetails<PlatformDetails>[]
}

export function MarketplaceProductForm(props: Props) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [isLoading, setIsLoading] = useState(!props.isNew);
    const [validateOffer, setValidateOffer] = useState(!props.isNew);
    const [developerIds, setDeveloperIds] = useState([]);
    const [isModified, setIsModified] = useState(false);
    const [editMediaAssets, setEditMediaAssets] = useState(false);
    const [editRentals, setEditRentals] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [marketplacePackageDetails, setMarketplacePackageDetails] = useState<MisPackageDetailsResponse>();
    const [contentPackageDetails, setContentPackageDetails] = useState<PlatformDetails>();

    useEffect(() => {
        if (props.marketplacePackage.publisherId.value) {
            GetDeveloperIds(props.auth, props.marketplacePackage.publisherId.value).then((result: any) => {
                setDeveloperIds(result);
            });
        }
    }, [props.auth, props.marketplacePackage.publisherId.value]);

    useEffect(() => {
        if (!props.isNew && props.marketplacePackage.packageName.value) {
            GetMarketplacePackageDetails(props.auth, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string).then((result: PackageDetails<MisPackageDetailsResponse>) => {
                const details = getLatestMarketplacePackageDetailsResponse<MisPackageDetailsResponse>(result);
                setMarketplacePackageDetails(details);
                props.marketplacePackage.tags.value = details?.marketplaceInfo?.tags;
                props.marketplacePackage.tags.originalValue = details?.marketplaceInfo?.tags;
                props.marketplacePackage.aircraftSpecifications.value = details?.marketplaceInfo?.aircraftSpecifications;
                props.marketplacePackage.historicalDescription.value = details?.localizedTexts["en-US"]?.texts["historicalDescription"];
                setIsLoading(false);
            });

            GetContentPackageDetails(props.auth, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string).then((result: PackageDetails<PlatformDetails>) => {
                const details = getLatestContentPackageDetailsResponse(result);
                setContentPackageDetails(details);
            });
        }
    }, [props.auth, props.marketplacePackage.packageName.value]);

    function processFormInfo(marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) { 
        props.processFormInfo(marketplacePackage, propertyName, propertyValue);

        setIsModified(true);
        forceUpdate();
    }

    function onEditMediaAssets() {
        setEditMediaAssets(true);
    }

    function onSaveMediaAssets(submission: MarketplacePackage) {
        setEditMediaAssets(false);
        setIsModified(true);
    }

    function onCancelEditMediaAssets() {
        setEditMediaAssets(false);
        setIsModified(false);
    }

    function onEditRentals() {
        setEditRentals(true);
    }

    function onSaveRentals(submission: MarketplacePackage) {
        setEditRentals(false);
        setIsModified(true);
    }

    function onCancelEditRentals() {
        setEditRentals(false);
        setIsModified(false);
    }

    async function onSave() {
        setValidateOffer(true);

        if (!props.marketplacePackage.validateOffer(true, true, false).passed) {
            return;
        }

        setWaiting(true);
        
        props.marketplacePackage.submissionId.value = new Date().toISOString().replace(':', '_') + ';' + getPackageNameNoPrefix(props.marketplacePackage.packageName.value);

        let marketplaceIngestRequest = props.marketplacePackage.MarketplaceIngestRequest;

        marketplaceIngestRequest.filenameUploadUrls = {};

        if (props.marketplacePackage.mediaAssets.value) {
            await Promise.all(props.marketplacePackage.mediaAssets.value.map(async (mediaAsset: MisMediaAsset) => {
                if (mediaAsset.file) {
                    marketplaceIngestRequest.filenameUploadUrls[mediaAsset.filename as keyof object] = await UploadMediaAsset(props.auth, mediaAsset.file, mediaAsset.filename, marketplaceIngestRequest.submissionId, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string, marketplaceIngestRequest.developerId, marketplaceIngestRequest.publisherId);
                }
            }));
        }

        const result = await IngestMarketplaceProduct(props.auth, marketplaceIngestRequest);

        const foundIndex = props.marketplacePackageSummaries.findIndex((marketplacePackage) => marketplacePackage.packageName === result.packageName);
        props.marketplacePackageSummaries[foundIndex] = result;

        await props.onSave();
        setWaiting(false);
    }

    const marketplacePackageFormFields = props.marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, validateOffer, true, true, false, {
        packageNames: [], // props.contentPackagesDetails?.map(contentPackageDetails => contentPackageDetails.packageName).filter(packageName => !props.marketplacePackagesDetails.map(marketplacePackageDetails => marketplacePackageDetails.packageName).includes(packageName)),
        developerIds: developerIds,
        auth: props.auth
    });

    const validOffer = !validateOffer || props.marketplacePackage.validateOffer(true, true, false).passed;
    const isRentable = ["LIVERY", "AIRCRAFT", "SCENERY", "PACK"].includes(props.marketplacePackage.packageContentType?.value ?? "");

    return (
        <div className={"container create-new"} style={{cursor: waiting || isLoading ? "wait" : ""}}>
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {props.marketplacePackage && <MarketplaceProductThumbnails key={editMediaAssets ? props.marketplacePackage.packageName?.value ?? "" : "mediaAssets"} show={editMediaAssets} auth={props.auth} marketplacePackage={props.marketplacePackage} marketplacePackageDetails={marketplacePackageDetails} onSave={onSaveMediaAssets} onCancel={onCancelEditMediaAssets} />}
                        {props.marketplacePackage && <MarketplaceProductRentals key={editRentals ? props.marketplacePackage.packageName?.value ?? "" : "rentals"} show={editRentals} auth={props.auth} marketplacePackage={props.marketplacePackage} marketplacePackageDetails={marketplacePackageDetails} onSave={onSaveRentals} onCancel={onCancelEditRentals} />}
                        {marketplacePackageFormFields}
                        <h2 style={{color: "red", textAlign: "center", visibility: validOffer === false ? "visible" : "hidden"}}>Please correct any errors indicated in red before saving.</h2>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <FontAwesomeIcon style={{cursor: isLoading ? "wait" : "default", position: "absolute", bottom: "30px", left: "50px"}} size="xl" icon={faImages} className="icon" onClick={isLoading ? undefined : () => onEditMediaAssets()} />
                            {isRentable && <FontAwesomeIcon style={{cursor: isLoading ? "wait" : "default", position: "absolute", bottom: "30px", left: "100px"}} size="xl" icon={faCoins} className="icon" onClick={isLoading ? undefined : () => onEditRentals()} />}
                            <Button style={{ margin: 10, width: 100 }} variant="primary"
                                onClick={async event => await onSave()}
                                title={validOffer ? "" : "Please correct any errors indicated in red before saving."}
                                disabled={!isModified || isLoading || props.marketplacePackage.packageName.value == null || validateOffer && !validOffer}>{"SAVE"}</Button>
                            <Button style={{ margin: 10, width: 100 }} variant="default"
                                onClick={event => props.onCancel()}>{"CANCEL"}</Button>
                        </div>
                </main>
            </div>
        </div>
    );
}
