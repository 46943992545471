export enum UserAccessLevel {
    Administrator = 'Administrator',
    Full = 'Full',
    RoleAssigned = 'RoleAssigned'
}

export class User {
    id?: string;
    _etag?: string;
    created?: string;
    modified?: string;
    email: string = '';
    name: string = '';
    gamerTag: string = '';
    xuid: string = '';
    isApproved: boolean = false;
    enableBetaFeatures: boolean = false;
    mcpAccessLevel: UserAccessLevel = UserAccessLevel.RoleAssigned;
    developers: { [key: string]: any } = {};
    publishers: { [key: string]: any } = {};
}