import React, { Component } from 'react';

import withRouter from '../withRouter';

import { GetValidationReport } from '../Api/WebApi'

import { Badge, Button, Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { center, reportDetails, formStyle, loadingStyle } from '../Constants/styles'

import { ReportPanel } from '../Components/Forms/ReportPanel'

import { ConvertMapToTableRows, camelPad } from '../Constants/utils'

import { TEST_DECISION_INFO, VALIDATION_DECISION_INFO} from '../Constants/enums'

import loading from '../Images/loading.svg';


class ViewValidationReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.auth,
            reportId: this.props.params.reportId,
            report: null
        };

    }

    componentDidMount() {

        var reportId = this.props.params.reportId;
        //console.log("reportId", reportId);
        GetValidationReport(this.state.auth, this.props.game, reportId).then((result) => {
            this.setState(() => ({ report: result }));
        }).catch((error) => {
            console.log("testReportError", error);
        });
    }


    constructPanelList() {
        const {
            report
        } = this.state;

        var panelList = [];
        var testResults = report.results;
        

        for (var decision in TEST_DECISION_INFO) {
            if (TEST_DECISION_INFO.hasOwnProperty(decision)) {

                var messagesList = {};

                for (var i in testResults) {
                    if (testResults[i].type === decision) {
                        var testName = camelPad(testResults[i].testName);
                         
                        if(!messagesList.hasOwnProperty(testName)){
                            messagesList[testName] = [];
                        }

                        var index = messagesList[testName].length + 1;

                        var message = <div key={index + testName}  style={{ marginBottom: 40 }} className="validationparent">
                            <p className="left" style={{ whiteSpace: "pre-wrap", fontWeight: "bold"}}>{index}</p>
                            <div className="right"> 
                                <p style={{ whiteSpace: "pre-wrap"}}><span style={{ fontWeight: "bold"}}>File</span>: {testResults[i].file}</p>
                                <p style={{ whiteSpace: "pre-wrap"}}><span style={{ fontWeight: "bold"}}>Message</span>: {testResults[i].message}</p>
                            </div>
                        </div>

                        messagesList[testName].push(message);
                    }
                }

                var allMessages = []

                var key=0;
                for (var prop in messagesList) {
                    if (messagesList.hasOwnProperty(prop)) {
                        if (messagesList[prop]) {
                            key++;
                            allMessages.push(
                                <h4 key={key + "h4"} style={{ textDecoration: "underline", fontWeight: "bold" }}>{prop}</h4>
                            )
                            var array = messagesList[prop];
                            for(var j in array){
                                allMessages.push(array[j]);
                            }
                            
                        }
                    }
                }

                if(allMessages.length > 0){
                    panelList.push(<ReportPanel key={i + decision} color={TEST_DECISION_INFO[decision].color}
                        headerTitle={TEST_DECISION_INFO[decision].readable + "s"}
                        headerSubTitle={""}
                        panelBody={allMessages}
                        keyValue={i} ></ReportPanel>)
                }

                
            }
        }


        return panelList;
    }


    render() {

        const {
            report
        } = this.state;



        if (!!report) {

            var labelStyle = VALIDATION_DECISION_INFO[report.decision].labelStyle;

            var options = { hour12: true, year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }
            var date = new Date(parseFloat(report.inputMetadata.validationTime)).toLocaleDateString('en-US', options);
            var offerDetail = [
                { name: "Pack Type", value: report.inputMetadata.packType, id: "packType" },
                { name: "Submitted Version", value: report.inputMetadata.submittedVersion, id: "submittedVersion" },
                { name: "Last Submitted", value: date, id: "lastSubmittedDate" },
            ];


            var offerTableRows = ConvertMapToTableRows(offerDetail);
            var panels = this.constructPanelList();
            return (
                <div className="container test-report">
                    <main className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <Card style={formStyle}>
                                    <Button style={{ marginTop: 20, float: "right", maxWidth:40 }} variant="success"
                                        onClick={event => window.print()}><FontAwesomeIcon icon={faPrint}/></Button>
                                    <h1 style={center}>Validation Report</h1>
                                    <h2 style={center}>{report.inputMetadata.contentName}</h2>
                                    <table style={{ ...reportDetails }} className="table-condensed table table-hover">
                                        <tbody>
                                            {offerTableRows}
                                        </tbody>
                                    </table>
                                    <h1 style={{ marginBottom: 30 }}><Badge variant={labelStyle}>STATUS: {VALIDATION_DECISION_INFO[report.decision].readable}</Badge></h1>
                                    {panels}
                                </Card>
                            </div>
                        </div>
                    </main>
                </div>
            )
        }
        else {
            return (
                <main className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Card style={formStyle}>
                                <div>
                                    <img src={loading} style={loadingStyle} alt="loading" />
                                </div>
                            </Card>
                        </div>
                    </div>
                </main>

            )
        }
    }
}
export default withRouter(ViewValidationReport);