import React, { Component } from 'react';

import { GetReport } from '../Api/WebApi'

import { Badge, Button, Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { center, reportDetails, formStyle } from '../Constants/styles'

import { ConvertMapToTableRows, FormatDateTime, ParseAttachmentNameFromUrl } from '../Constants/utils'

import { DECISION_TYPE, DARSY_TASK_TYPE, POLICY_DECISIONS, REPORT_DECISIONS, READABLE_REPORT_DECISIONS } from '../Constants/enums'

class ViewReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.auth,
            reportId: this.props.params.reportId,
            report: null
        };

    }

    componentDidMount() {
        var reportId = this.props.params.reportId;
        //console.log("reportId", reportId);
        GetReport(this.state.auth, reportId).then((result) => {
            //console.log("result", result);
            this.setState(() => ({ report: result }));
        }).catch((error) => {
            console.log("testReportError", error);
        });
    }

    convertPolicyToPanel(policy) {

        //("policyToPanel", policy)

        var labelStyle = "warning";

        if (policy.decision === POLICY_DECISIONS.FAIL) {
            labelStyle = "danger"
        } else if (policy.decision === POLICY_DECISIONS.PASS_WITH_NOTES) {
            labelStyle = "warning";
        } else if (policy.decision === POLICY_DECISIONS.PASS) {
            labelStyle = "success";
        }


        var attachments = [];
        if (typeof policy.attachments !== 'undefined') {
            policy.attachments.forEach(function (attachment, index) {
                //("attachment", attachment);

                if (attachment === "attachmenterror") {
                    attachments.push(<div key={index}><p style={{ color: "#f04124" }}> Error loading attachment, please contact your partner manager! </p></div>)
                }
                else {
                    var attachmentName = ParseAttachmentNameFromUrl(attachment);
                    attachments.push(<div key={index}><a target="_blank" rel="noopener noreferrer" href={attachment} > {attachmentName} </a></div>)
                }

            })
        }

        if (policy.decision === POLICY_DECISIONS.PASS &&
            attachments.length === 0) {
            return;
        }

        var colorOverride = "";

        var platforms = <div><h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>Platforms</h4>
        <p style={{ whiteSpace: "pre-wrap" }}>{policy.platforms}</p></div>
        if (policy.platforms) {
            if (policy.platforms.length === 1) {
                if (policy.platforms[0] === "All Platforms") {
                    platforms = "";
                }
            }
        }

        return (
            <Card text={labelStyle} defaultExpanded key={policy.policy_code} style={{ borderColor: colorOverride }}>
                <Card.Header>
                    <Card.Title toggle>
                        <span className='FontAwesomeIcon' />
                        {policy.policy_code}&nbsp;&nbsp;&nbsp;{policy.title} <span style={{ float: "right" }}> {DECISION_TYPE[policy.decision]} </span>
                    </Card.Title>
                </Card.Header>
                <Card.Collapse>
                    <Card.Body>

                        <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>Notes</h4>
                        <p style={{ whiteSpace: "pre-wrap" }}>{policy.reason}</p>
                        {platforms}
                        {attachments.length > 0 &&
                            <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>Attachments</h4>
                        }
                        {attachments}
                        <p style={{ color: "gray", marginTop: 20 }}> <span style={{ fontWeight: "bold" }} > Note: </span> Attachment links expire after one hour. If this happens please refresh the page for a new link. </p>
                    </Card.Body>
                </Card.Collapse>
            </Card>
        );
    }

    constructPanelList(report, decision) {
        var policies = report.review_results.policies;

        var policyList = [];

        Object.keys(policies).forEach(key => {
            var policy = policies[key];
            policy.policy_code = key;
            if (policy.decision === decision) {
                policyList.push(policy);
            }
        })

        var policyPanels = []


        policyList.forEach(item => {
            policyPanels.push(this.convertPolicyToPanel(item))
        })

        return policyPanels;


    }


    render() {

        const {
            report
        } = this.state;



        if (!!report) {
            if (report.version !== "1.0") {
                return (
                    <Card style={formStyle}>
                        <h1 style={center}> Sorry this task report is deprecated and cannot be viewed :( </h1>
                    </Card>
                )
            }
            var offerDetail = [
                { name: "Content Type", value: report.content_type, id: "contentType" },
                { name: "Submitted Version", value: report.review_results.darsy_approved_content_version, id: "submittedVersion" },
                { name: "Last Submitted", value: FormatDateTime(report.submission_date), id: "lastSubmittedDate" },
                { name: "Review Date", value: FormatDateTime(report.review_date), id: "reviewDate" },
                { name: "Client Version", value: report.review_results.darsy_approved_client_version, id: "clientVersion" },

            ];

            var offerTableRows = ConvertMapToTableRows(offerDetail);

            var labelStyle = "success";

            if (report.review_results.decision === REPORT_DECISIONS.FAIL) {
                labelStyle = "danger"
            } else if (report.review_results.decision === REPORT_DECISIONS.PASS_WITH_NOTES) {
                labelStyle = "warning";
            } else if (report.review_results.decision === REPORT_DECISIONS.PASS) {
                labelStyle = "success";
            } else if (report.review_results.decision === REPORT_DECISIONS.INVESTIGATING) {
                labelStyle = "warning";
            }

            var failPanels = this.constructPanelList(report, POLICY_DECISIONS.FAIL);
            var passNotesPanels = this.constructPanelList(report, POLICY_DECISIONS.PASS_WITH_NOTES);

            var passPanels = this.constructPanelList(report, POLICY_DECISIONS.PASS);

            var investigatePanels = this.constructPanelList(report, POLICY_DECISIONS.INVESTIGATING);

            //console.log("failPanels", failPanels)
            //console.log("passNotesPanels", passNotesPanels)

            var policyTest = [];
            policyTest.push(this.convertPolicyToPanel(labelStyle));

            var topNotice = null;
            if (report.task_type === "DarsyMPQA") {
                topNotice = <Card style={{ padding: 10 }}>
                    <p style={{ color: "gray" }}> <span style={{ fontWeight: "bold" }} > Notice: </span> Microsoft tests all content to ensure the integrity of the Minecraft brand, Marketplace, platform, and customer experience. The following report lists all issues found during the test. This report is NOT representative of all issues that may exist. It is the sole responsibility of the content creator to thoroughly test their content before submission. Failure to do so could result in delayed reporting, additional certification passes, and missed release dates. </p>
                </Card>
            }

            return (
                <div className="container test-report">
                    <div className="row">
                        <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <Card style={formStyle}>
                                {topNotice}
                                <Button style={{ marginTop: 20, float: "right", maxWidth:40 }} variant="success"
                                        onClick={event => window.print()}><FontAwesomeIcon icon={faPrint}/></Button>
                                <h1 style={center}>{DARSY_TASK_TYPE[report.task_type]} Report</h1>
                                <h2 style={center}>{report.content_title}</h2>
                                <table style={{ ...reportDetails }} className="table-condensed table table-hover">
                                    <tbody>
                                        {offerTableRows}
                                    </tbody>
                                </table>

                                <h1 style={{ marginBottom: 30 }}><Badge variant={labelStyle}>STATUS: {READABLE_REPORT_DECISIONS[report.review_results.decision]}</Badge></h1>

                                {failPanels}
                                {passNotesPanels}
                                {passPanels}
                                {investigatePanels}

                                <Card style={{ padding: 10 }}>
                                    <p style={{ color: "gray" }}> <span style={{ fontWeight: "bold" }} > Minecraft Bugs: </span> Are you encountering a bug in your content that you can't fix due to the game? Contact your Partner Manager or log a bug directly in JIRA using the <a target="_blank" rel="noopener noreferrer" href={"https://....microsoft.com/en-us/"}>Bug Tracker</a>.</p>
                                </Card>
                                <Card style={{ padding: 10 }}>
                                    <p style={{ color: "gray" }}> <span style={{ fontWeight: "bold" }} > Policy Definitions: </span> For a full list of policies and their definitions, please view our  <a target="_blank" rel="noopener noreferrer" href={"https://....partners/hc/en-"}>Policy Documentation</a>.</p>
                                </Card>
                            </Card>
                        </main>
                    </div>
                </div>

            )
        }
        else {
            return (
                <div>
                </div>
            )
        }
    }
}
export default ViewReport;