import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { ImageGridFormField, ImageGridFormFieldValue } from './FormFields/FormField';
import { MisMediaAsset, MisMediaAssetTag, MisPackageDetailsResponse } from '../Models/AugerOffers/MarketplaceDetails';

interface Props {
    auth: Auth,
    onSave: (marketplacePackage: MarketplacePackage) => void,
    onCancel: () => void,
    show: boolean,
    marketplacePackage: MarketplacePackage,
    marketplacePackageDetails?: MisPackageDetailsResponse
}

export function MarketplaceProductThumbnails(props: Props) {
    const [urlsLoaded, setUrlsLoaded] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);

    useEffect(() => {
        if (!urlsLoaded && props.marketplacePackageDetails) {
                props.marketplacePackage.mediaAssets.value = props.marketplacePackageDetails.marketplaceInfo.layout?.mediaAssets ?? [];

                props.marketplacePackage.mediaAssets.value.forEach((mediaAsset: MisMediaAsset) => {
                        mediaAsset.url = props.marketplacePackageDetails?.files?.find(properties => properties.name === mediaAsset.filename)?.url
                });

                setUrlsLoaded(true);
            }
    }, [props.auth, props.marketplacePackageDetails]);

    function processFormInfo(marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) { 
        if (!marketplacePackage || !propertyValue) {
            return;
        }
    
        if (propertyName as string === 'mediaAssets') {
            const removedMediaAssets = marketplacePackage?.mediaAssets.value?.filter(asset => !(propertyValue as ImageGridFormFieldValue[])?.find(x => x.filename === asset.filename));

            if (removedMediaAssets && removedMediaAssets.length !== 0) {
                marketplacePackage.deletedFiles.value = removedMediaAssets.map(mediaAsset => mediaAsset.filename);
            }

            setEnableSaveButton(true);
        }

        marketplacePackage.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    return (
        <Modal show={props.show} onHide={props.onCancel} size={"xl"} backdrop={"static"} keyboard={false} centered={true}>
            <Modal.Header closeButton  style={{display: "flex", alignItems: "center"}} >
                <Modal.Title>Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImageGridFormField
                        key={"marketplaceThumbnails"}
                        context={props.marketplacePackage}
                        value={props.marketplacePackage?.mediaAssets.value ?? []}
                        helpContent={"Extracted from package."}
                        propertyName={"mediaAssets"}
                        isNew={true}
                        auth={props.auth}
                        offerUndo={false}
                        optionsList={Object.keys(MisMediaAssetTag).map(value => { return { key: value, value: value } })}
                        returnCallback={processFormInfo} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' disabled={!enableSaveButton} onClick={() => props.onSave(props.marketplacePackage)}>Save</Button>
                <Button variant='secondary' onClick={props.onCancel}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
