
import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button, Col, Popover, OverlayTrigger, FormCheck, FormGroup, FormControl, FormLabel, Image, ProgressBar, Row, Container, InputGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faClose, faInfoCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import loadingsmall from '../../Images/loadingsmall.svg';
import { CustomModal } from '../CustomModal';
import { ValidationUploader } from '../ValidationUploader';
import { ValidationResultGetter } from '../ValidationResultGetter';
import { VALIDATION_DECISIONS, VALIDATION_DECISION_INFO, UPDATE_STATUS } from '../../Constants/enums';
import {MultiSelect} from '../MultiSelect'
import TagInput, { Tag } from './TagInput';
import Auth from '../../Auth/Auth';
import { OfferField } from '../../Models/AugerOffers/MarketplaceItem';

export type FormFieldValidationResult = {
    result: "error" | "success" | "warning" | null,
    message?: string,
    blockSubmission?: boolean,
    requestConfirmation?: boolean
};

interface FormFieldProps<V, T> {
    value?: V;
    offerField?: OfferField<V>;
    title?: string;
    className?: string;
    context?: T;
    popoverContent?: ReactElement;
    placeholder?: string;
    helpContent?: string;
    propertyName: string;
    modalTitle?: string;
    modalBody?: string;
    modalPrompt?: string;
    displayModal?: boolean;
    isNew?: boolean;
    disabled?: boolean;
    offerUndo?: boolean;
    showPreviousValue?: boolean;
    inline?: boolean;
    visible?: boolean;
    validate?: boolean;
    enablePCAndXbox?: boolean;
    enablePCOnly?: boolean;
    enableXboxOnly?: boolean;
    returnCallback?: (offer: T | undefined, propertyName: keyof T, value?: V) => void;
    validateCallback?: (offer?: T, value?: V, originalValue?: V, enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) => FormFieldValidationResult;
}

interface FormFieldState<V> {
    value?: V;
    originalValue?: V;
    title: string;
    popoverContent: ReactElement;
    placeholder: string;
    helpContent: string;
    modalTitle: string;
    modalBody: string;
    modalPrompt: string;
    displayModal: boolean;
    isNew: boolean;
    infoButton?: ReactElement;
}

class FormField<V, T, P extends FormFieldProps<V, T> = FormFieldProps<V, T>, S extends FormFieldState<V> = FormFieldState<V>> extends Component<P, S> {
    constructor(props: P) {
        super(props);

        this.state = this.getInitialState();

        // this.props.returnCallback(this.props.offer, this.props.propertyName as keyof T, this.state.value);
    }

    getInitialState(): S {
        return {
            value: this.props.value,
            originalValue: this.props.value,
            title: this.props.title,
            popoverContent: this.props.popoverContent,
            placeholder: this.props.placeholder,
            helpContent: this.props.helpContent,
            modalTitle: this.props.modalTitle,
            modalBody: this.props.modalBody,
            modalPrompt: this.props.modalPrompt,
            displayModal: false,
            isNew: this.props.isNew ?? false
        } as S;
    }
    
    getOriginalValue(): V | undefined {
        return this.props.offerField?.originalValue ?? this.state.originalValue;
    }

    getValidationState(enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) {
        return this.props.validateCallback ? this.props.validateCallback(this.props.context, this.state.value, this.getOriginalValue(), enablePCAndXbox, enablePCOnly, enableXboxOnly).result : undefined;
    }

    getToolTip(enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) {
        return this.props.validate === false ? undefined : this.getValidationMessage(enablePCAndXbox, enablePCOnly, enableXboxOnly) || this.state.helpContent;
    }

    getValidationMessage(enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) {
        if (this.props.validateCallback) {
            const {
                result,
                message
            } = this.props.validateCallback(this.props.context as T, this.state.value, this.getOriginalValue(), enablePCAndXbox, enablePCOnly, enableXboxOnly);
            
            return (result === 'error' || result === 'warning') && message ? message : "N/A";
        } else {
            return "N/A";
        }
    }

    componentDidUpdate(prevProps: FormFieldProps<V, T>, prevState: FormFieldState<V>) {
        if (prevProps.value !== this.props.value) {
            this.setState(() => ({ value: this.props.value }));
        }
    }

    handleChange(event: V) {
        this.setState(() => ({ value: event }));

        if (this.props.returnCallback) {
            this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, event);
        }
    }

    resetToDefault() {
        //console.log("restting to default", this.getOriginalValue());
        this.setState(() => ({ value: this.getOriginalValue() as V }));

        if (this.props.returnCallback) {
            this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, this.getOriginalValue());
        }
    }

    populateFormControl(validationState?: "error" | "success" | "warning" | null | undefined): ReactElement | null {
        return null;
    }

    populateDefaultFormControl(): ReactElement | null {
        return null;
    }

    render() {
        const {
            title,
            value,
            popoverContent,
            isNew
        } = this.state

        var hasChanged = (
            isNew ||
            value === this.getOriginalValue() ||
            Array.isArray(value) && Array.isArray(this.getOriginalValue()) && value.length === (this.getOriginalValue() as [])?.length && value.every((val, index) => val === (this.getOriginalValue() as [])[index])) ?
                false :
                true;

        var popoverTop =
            <Popover id="popover-positioned-top" >
                {popoverContent}
            </Popover>
            ;

        var validationState = this.props.validate === false ? undefined : this.getValidationState(this.props.enablePCAndXbox, this.props.enablePCOnly, this.props.enableXboxOnly);
        const validationMessage = this.getValidationMessage(this.props.enablePCAndXbox, this.props.enablePCOnly, this.props.enableXboxOnly);
        var formControl = this.populateFormControl(validationState);
        var defaultFormControl = this.populateDefaultFormControl();

        return (
            <FormGroup
                style={this.props.inline ? { display: "flex", alignItems: "center", marginRight: "10px", visibility: this.props.visible === false ? "hidden" : "unset" } : { visibility: this.props.visible === false ? "hidden" : "unset" }}
                className="youtube form-group"
                controlId={title}
                title={this.getToolTip(this.props.enablePCAndXbox, this.props.enablePCOnly, this.props.enableXboxOnly)}
            >
                {title && <FormLabel title={this.state.helpContent} style={this.props.inline? { marginBottom: 0, paddingRight: 5, whiteSpace: "nowrap" } : { paddingRight: 5 }}>{title}</FormLabel>}
                {this.state.infoButton}

                {popoverContent &&
                    <OverlayTrigger trigger={['hover', 'focus']} rootClose delay={1000} placement="top" overlay={popoverTop}>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ padding: 0 }} tabIndex={-1}></FontAwesomeIcon>
                    </OverlayTrigger>
                }
                {/* <HelpBlock style={{ paddingLeft: 5 }}>{this.getValidationState(this.props.value) !== 'success' ? this.getToolTip(this.state.value, originalValue) : ""}</HelpBlock> */}
                <FormControl.Feedback />
                {formControl}

                {hasChanged && this.props.showPreviousValue !== false && defaultFormControl &&
                    <div style={{paddingTop: 5}}>
                        Previous value
                        <div style={{paddingTop: 5}}>
                            {defaultFormControl}
                        </div>
                    </div>
                }

                {hasChanged && this.props.offerUndo &&
                    <div>
                        <b style={{color: 'orange', verticalAlign: 'bottom'}}>{title} has changed since the previous submission. </b>
                        <Button tabIndex={-1} style={{ marginTop: 5, marginLeft: 5 }} onClick={() => this.resetToDefault()} size="sm" title="Revert to previous value? Note: reverting will not modify the package, just the value submitted for tracking purposes. Please update the package if the value should match the previous submission.">
                            <FontAwesomeIcon icon={faUndo} className="icon" />
                        </Button>
                    </div>
                }

                <div>
                    <b style={{color: validationState === 'warning' ? 'orange' : 'red', verticalAlign: 'bottom', visibility: !validationState || validationState === "success" ? 'hidden' : 'unset'}}>{validationMessage}</b>
                </div>


            </FormGroup>
        );
    }
}

export class TextFormField<T> extends FormField<string, T> {
    populateFormControl(validationState: "error" | "success" | "warning" | null | undefined) {
        return <FormControl
            type="text"
            value={this.state.value ?? ""}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            onChange={(event) => this.handleChange((event as any).target.value)}
            isValid={(validationState === 'success' || validationState === 'warning') && (this.props.isNew ||  this.state.value !== this.getOriginalValue())}
            isInvalid={validationState === 'error' || validationState === null}
        />
    }

    populateDefaultFormControl() {
        return <FormControl
            type="text"
            value={this.getOriginalValue()}
            disabled={true}
        />
    }
}

export class TextAreaFormField<T> extends FormField<string, T> {


    populateFormControl(validationState: "error" | "success" | "warning" | null | undefined) {
        return <FormControl
            style={{ minHeight: 200, resize: "vertical" }}
            disabled={this.props.disabled}
            as="textarea"
            value={this.state.value?.replace(/<br>/g, "\n") ?? ""}
            placeholder={this.state.placeholder}
            onChange={(event) => this.handleChange((event as any).target.value.replace(/\n/g, "<br>"))}
            isValid={(validationState === 'success' || validationState === 'warning') && (this.props.isNew || this.state.value !== this.getOriginalValue())}
            isInvalid={validationState === 'error' || validationState === null}
        />
    }
}

interface TagFormFieldProps<T> extends FormFieldProps<string[], T> {
    optionsList?: Tag[];
    sort?: boolean;
}

export class TagFormField<T> extends FormField<string[], T, TagFormFieldProps<T>> {
    populateFormControl() {
        return (
            <TagInput
                tagValues={this.state.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                sort={this.props.sort}
                options={this.props.optionsList}
                returnCallback={(tagList: any) => this.handleChange(tagList)}
            />
        )
    }

    populateDefaultFormControl() {
        return (
            <TagInput
                tagValues={this.getOriginalValue()}
                disabled={true}
                options={this.props.optionsList}
            />
        )
    }
}

export class DisplayField<V, T> extends FormField<V, T> {
    constructor(props: FormFieldProps<V, T>) {
        super(props);

        this.processChange = this.processChange.bind(this);
    }

    processChange(target: V) {
        this.handleChange(target);
    }

    populateFormControl() {
        return (
            <div className={this.props.className}>
                <h3 style={{ marginTop: 0 }}>{(this.state.value as string)}</h3>
            </div>
        )
    }
}

type DropDownFormFieldOption = {
    option: string;
    disabled: boolean;
    display?: string;
}

interface DropDownFormFieldProps<T> extends FormFieldProps<string, T> {
    optionsList: DropDownFormFieldOption[];
    sort?: boolean;
    selectAllOption?: boolean;
    allowCustomValue?: boolean;
}

interface DropDownFormFieldState extends FormFieldState<string> {
    optionsList: DropDownFormFieldOption[];
}

export class DropDownFormFieldNew<T> extends FormField<string, T, DropDownFormFieldProps<T>, DropDownFormFieldState> {
    getInitialState(): DropDownFormFieldState {
        var initialStates = super.getInitialState();
        initialStates.optionsList = this.props.optionsList;
        return initialStates;
    }

    populateFormControl() {

        const {
            optionsList
        } = this.state;

        var optionDisplay = Object.keys(optionsList).map((key, index) => {

            return <option value={key} key={index + 1}>{optionsList[index].option}</option>
        }
        )

        if (this.props.sort === true) {
            optionDisplay = optionDisplay.sort((function (a, b) {
                return (a.props.children > b.props.children) ? 1 : -1;
            }))
        }

        var noneString = "Please select the " + this.props.title;
        optionDisplay.unshift(<option value={""} key={0}>{noneString}</option>);

        return <FormControl
            as="select"
            placeholder="select"
            disabled={this.props.disabled}
            value={this.state.value ?? ""}
            onChange={(event) => this.handleChange((event as any).target.value)}>
            {optionDisplay}
        </FormControl>
    }
}

export interface NameValuePair
{
    name: string;
    value: string;
}

export class NameValuePairFormField<T> extends FormField<NameValuePair[], T> {
    getInitialState(): FormFieldState<NameValuePair[]> {
        var initialStates = super.getInitialState();
        initialStates.infoButton = <FontAwesomeIcon icon={faCirclePlus} className="icon" onClick={() => this.handleAddKeyValuePair()}/>;
        return initialStates;
    }

    handleKeyChange(row: NameValuePair, index: number, event: any) {
        var newRows = this.state.value?.slice() ?? [];

        newRows[index].name = event;

        this.handleChange(newRows);
    }

    handleValueChange(row: NameValuePair, index: number, event: any) {
        var newRows = this.state.value?.slice() ?? [];

        newRows[index].value = event;

        this.handleChange(newRows);
    }

    handleAddKeyValuePair() {
        var newRows = this.state.value?.slice() ?? [];

        newRows.push({ name: "", value: "" });

        this.handleChange(newRows);
    }

    populateFormControl() {
        return <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td style={{padding: "0px"}}>
                            <FormControl
                                type="text"
                                style={{border: "0px", borderRadius: "0px"}}
                                value={"Name"}
                                disabled={true} />
                        </td>
                        <td style={{padding: "0px"}}>
                            <FormControl
                                type="text"
                                style={{border: "0px", borderRadius: "0px"}}
                                value={"Value"}
                                disabled={true} />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.value?.map((row: NameValuePair, index: number) =>
                        <tr key={index}>
                            <td style={{padding: "0px"}}>
                                <FormControl
                                    type="text"
                                    style={{border: "0px", borderRadius: "0px"}}
                                    value={row.name ?? ""}
                                    title={row.name}
                                    disabled={this.props.disabled}
                                    onChange={(event) => this.handleKeyChange(row, index, (event as any).target.value)} />
                            </td>
                            <td style={{padding: "0px"}}>
                                <FormControl
                                    type="text"
                                    style={{border: "0px", borderRadius: "0px"}}
                                    value={row.value ?? ""}
                                    title={row.value}
                                    disabled={this.props.disabled}
                                    onChange={(event) => this.handleValueChange(row, index, (event as any).target.value)} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table> 
        </div> 
    }
}

export class MultiSelectFormField<T> extends FormField<string, T, DropDownFormFieldProps<T>, DropDownFormFieldState> {
    getInitialState(): DropDownFormFieldState {
        var initialStates = super.getInitialState();
        initialStates.optionsList = this.props.optionsList;
        return initialStates;
    }

    populateFormControl() {

        const {
            optionsList
        } = this.state;
/*
        var optionDisplay = Object.keys(optionsList).map((key, index) => {

            return <option value={key} key={index + 1}>{optionsList[key]}</option>
        }
        )

        if (this.props.sort === true) {
            optionDisplay = optionDisplay.sort((function (a, b) {
                return (a.props.children > b.props.children) ? 1 : -1;
            }))
        }
*/
        //var noneString = "Please Select A " + this.props.title;
        //optionDisplay.unshift(<option value={""} key={0}>{noneString}</option>);

        return (
        <div >
            <div className="form-control" style={{ height: "100%" }}>
                <MultiSelect
                    optionsList={optionsList}
                    value={this.state.value ?? ""}
                    selectAllOption={this.props.selectAllOption}
                    onChange={(event: any) => this.handleChange(event)}>
                </MultiSelect>
            </div>
        </div>);
    }
}

export class DropDownFormField<T> extends FormField<string, T, DropDownFormFieldProps<T>, DropDownFormFieldState> {
    getInitialState(): DropDownFormFieldState {
        var initialStates = super.getInitialState();
        initialStates.optionsList = this.props.optionsList;
        return initialStates;
    }

    populateFormControl(validationState?: "error" | "success" | "warning" | null | undefined) {
        const {
            optionsList
        } = this.state;

        var optionDisplay = Object.keys(optionsList).map((key, index) => <option value={optionsList[index].option} key={index} hidden={optionsList[index].disabled}>{optionsList[index].display ?? optionsList[index].option}</option>);

        if (this.props.sort === true) {
            optionDisplay = optionDisplay.sort((function (a, b) { return (a.props.children > b.props.children) ? 1 : -1; }));
        }

        return <FormControl
            as="select"
            placeholder="select"
            disabled={this.props.disabled}
            value={this.state.value ?? ""}
            onChange={(event) => this.handleChange((event as any).target.value)}
            isValid={(validationState === 'success' || validationState === 'warning') && (this.props.isNew || this.state.value !== this.getOriginalValue())}
            isInvalid={validationState === 'error' || validationState === null}>
            {this.props.allowCustomValue && optionsList.find((option) => option.option === this.state.value) === undefined && <option value={this.state.value} hidden>{this.state.value}</option>}
            <option value={this.props.placeholder} hidden>{this.props.placeholder}</option>
            {optionDisplay}
        </FormControl>
    }

    componentDidUpdate(prevProps: DropDownFormFieldProps<T>, prevState: DropDownFormFieldState) {
        super.componentDidUpdate(prevProps, prevState);

        if (prevProps.optionsList.length !== this.props.optionsList.length || prevProps.optionsList.some((value, index) => (value.option !== this.props.optionsList[index].option || value.disabled !== this.props.optionsList[index].disabled))) {
            this.setState(() => ({ optionsList: this.props.optionsList }));
 
            let currentOption = this.props.optionsList.find(o => o.option === this.state.value);
 
            if (!currentOption || currentOption.disabled) {
                let newOption = this.props.optionsList.find(o => o.disabled === false);
 
                if (this.state.value !== newOption?.option) {
                    this.handleChange(newOption?.option as any);
                }
            }
        }
    }
    
    populateDefaultFormControl() {
        const {
            optionsList
        } = this.state;

        return <FormControl
            type="text"
            disabled={true}
            value={this.getOriginalValue() ?? ""}
            onChange={(event) => this.handleChange((event as any).target.value)}>
        </FormControl>
    }
}

interface FileFormFieldProps<V, T > extends FormFieldProps<V, T> {
    inputKey: React.Key;
}

export class FileFormField<T> extends FormField<File, T, FileFormFieldProps<File, T>> {

    handleChange(event: any) {
        if (event[0]) {
            this.setState(() => ({ value: event[0] }));

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, event[0]);
            }
        }
        else {
            this.setState(() => ({ value: undefined }));

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, undefined);
            }
        }

    }

    populateFormControl() {
        //console.log("inputKey", this.props.inputKey);
            return <FormControl
                type="file"
                placeholder="Choose File"
                accept=".zip"
                key={this.props.inputKey}
                style={{ width: "100%" }}
                onChange={(e) => this.handleChange(((e.currentTarget as any) as HTMLInputElement).files)}>
            </FormControl>
    }
}

export class FileFormFieldCustom<T> extends FormField<File | undefined, T, FormFieldProps<File | undefined, T>> {

    handleChange(event: any) {
        if (event[0]) {
            this.setState(() => ({ value: event[0] }));

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, event[0]);
            }
        }
        else {
            this.setState(() => ({ value: undefined }));

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, undefined);
            }
        }

    }

    populateFormControl() {
            return <div style={{textAlign: 'center'}} >
                <InputGroup className="custom-file-button">
                    <label className="input-group-text" style={{background: "linear-gradient(#89b659, #73a839 60%, #6c9e36)", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", color: "white", width: '100%'}} htmlFor="inputGroupFile">Choose File...</label>
                    <FormControl
                        id="inputGroupFile"
                        type="file"
                        hidden={true}
                        accept=".zip"
                        onChange={(e) => this.handleChange(((e.currentTarget as any) as HTMLInputElement).files)} />
                </InputGroup>
            </div>
    }
}

export type ValidationReport = {
    decision: string;
    error?: string;
    inputMetadata: {
        contentName: string;
        submissionId: string;
    }
}

interface ValidationFormFieldProps<T> extends FormFieldProps<ValidationReport, T> {
    auth: Auth;
    game: string;
    offerType: string;
    offerId: string;
    binaryFile: File;
    isAnUpdate: boolean;
}

interface ValidationFormFieldState extends FormFieldState<ValidationReport> {
    offerType: string;
    uploadProgress: number;
    uploadStatus: string;
    validationId?: string;
    report?: ValidationReport;
}

export class ValidationFormField<T> extends FormField<ValidationReport, T, ValidationFormFieldProps<T>, ValidationFormFieldState> {
    constructor(props: ValidationFormFieldProps<T>) {
        super(props);

        this.progressCallback = this.progressCallback.bind(this);
        this.statusCallback = this.statusCallback.bind(this);
        this.reportCallback = this.reportCallback.bind(this);
    }

    getInitialState() {
        var initialState = super.getInitialState();
        initialState.uploadProgress = 0;
        initialState.uploadStatus = UPDATE_STATUS.notstarted;
        initialState.offerType = this.props.offerType ?? "unknown";
        return initialState;
    }

    componentDidUpdate(prevProps: ValidationFormFieldProps<T>, prevState: ValidationFormFieldState) {
        if (prevProps.binaryFile !== this.props.binaryFile) {
            this.setState(() => ({ ...this.getInitialState() }));
        }

    }

    progressCallback(percentage: number, id: string) {
        if (!this.props.binaryFile) {
            percentage = 0;
        }
        this.setState(() => ({ uploadProgress: percentage }));
    }

    statusCallback(newStatus: string, id: string, error = "") {

        if (this.state.validationId) {
            if (id !== this.state.validationId) {
                console.log("skipping status callback");
                return;
            }
        }

        this.setState(() => ({ uploadStatus: newStatus }));
        this.setState(() => ({ validationId: id }));

        if (newStatus === UPDATE_STATUS.success) {
            //console.log("upload successful");
            this.setState(() => ({
                report: {
                    decision: VALIDATION_DECISIONS.PENDING,
                    inputMetadata: {
                        contentName: this.props.binaryFile.name,
                        submissionId: id
                    }
                }
            }));
        }
        else if (newStatus === UPDATE_STATUS.failed) {
            this.setState(() => ({
                report: {
                    decision: VALIDATION_DECISIONS.FAIL,
                    error: error,
                    inputMetadata: {
                        contentName: this.props.binaryFile.name,
                        submissionId: id
                    }
                }
            }));
        }
    }

    reportCallback(report: ValidationReport) {
        if (report.inputMetadata.submissionId !== this.state.validationId) {
            console.log("ignoring erroneous report");
            return;
        }
        this.setState(() => ({ report: report }));
        this.handleChange(report);
    }

    constructReport(report: ValidationReport) {
        var symbol = (report.decision === VALIDATION_DECISIONS.PENDING) ? <img src={loadingsmall} style={{ maxWidth: 20, display: "block", margin: "auto" }} alt="loading" /> : <FontAwesomeIcon style={{ color: VALIDATION_DECISION_INFO[report.decision].color, display: "block", textAlign: "center" }} icon={VALIDATION_DECISION_INFO[report.decision].symbol} />;
        var name = VALIDATION_DECISION_INFO[report.decision].readable;
        var isViewable = VALIDATION_DECISION_INFO[report.decision].viewable;
        //console.log("isViewable", isViewable);
        var linkPath = "/viewValidationReport/" + report.inputMetadata.submissionId + "/"

        return (
            <div key={"table"} className="validationroot">
                <div id={"symbol"} className="validationresult symbol">
                    {symbol}
                </div>
                <div id={"decision"} className="validationresult decision">
                    {name}
                </div>
                <div id={"filename"} className="validationresult filename">
                    {report.error ?? report.inputMetadata.contentName}
                </div>
                <div id={"button"} className="validationresult button">
                    <Link to={linkPath} target="_blank" id="nounderline" style={{ textDecoration: 'none' }}>
                        <Button id="bootstrap-overrides" variant="success" size="sm" style={{ maxWidth: 70, margin: "auto" }}
                            disabled={!isViewable || report.error ? true : false}> VIEW </Button>
                    </Link>
                </div>
            </div>
        )
    }


    populateFormControl() {
        const {
            uploadProgress,
            uploadStatus,
            validationId,
            report,
            offerType,
        } = this.state;

        var reportDisplay = <div></div>;
        if (report) {
            //console.log("reportBeforeConstruction", report);
            reportDisplay = this.constructReport(report)
        }

        var newUploadProgress = uploadProgress;
        if (!this.props.binaryFile) {
            newUploadProgress = 0;
        }

        return <div id="validationformfield" className="form-control-feedback-container form-control" style={{ height: "100%" }} >
            {(uploadStatus === UPDATE_STATUS.pending || uploadStatus === UPDATE_STATUS.notstarted) &&
                <ProgressBar className="validationProgress" id="validationprogress" variant="success" now={newUploadProgress} label={`Uploading ${Math.round(newUploadProgress)}%`} />
            }
            {(uploadStatus === UPDATE_STATUS.success && report?.decision === VALIDATION_DECISIONS.PENDING) &&
                <ValidationResultGetter auth={this.props.auth} game={this.props.game} resultCallback={this.reportCallback} validationId={validationId} />
            }
            {(report !== null && this.props.binaryFile) &&
                reportDisplay
            }
            <ValidationUploader game={this.props.game} offer={this.props.context} reportCallback={this.props.returnCallback} progressCallback={this.progressCallback} statusCallback={this.statusCallback} offerId={this.props.offerId} offerType={offerType} isAnUpdate={this.props.isAnUpdate} auth={this.props.auth} binaryFile={this.props.binaryFile} />
        </div>
    }
}

export interface ImageGridFormFieldValue {
    filename: string,
    tags: string[],
    file?: File | Blob,
    url?: string
}

interface ImageGridFormFieldProps<T> extends FormFieldProps<ImageGridFormFieldValue[], T> {
    auth: Auth;
    optionsList: Tag[]
}

export class ImageGridFormField<T> extends FormField<ImageGridFormFieldValue[], T, ImageGridFormFieldProps<T>> {
    handleChange(event: any) {
        if (event[0]) {
            this.state.value?.push({
                filename: event[0].name,
                tags: [],
                file: event[0],
                url: URL.createObjectURL(event[0])
            });

            this.setState(() => ({ value: this.state.value }));

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, this.state.value);
            }
        }
    }

    handleTagChange(tagList: string[], index: number) {
        let value = this.state.value? this.state.value[index] : undefined;

        if (value) {
            value.tags = tagList;

            if (this.props.returnCallback) {
                this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, this.state.value);
            }
        }
    }

    onDeleteThumbnail(filename: string) {
        let value = this.state.value?.filter(element => element.filename !== filename);

        this.setState(() => ({ value: value }));

        if (this.props.returnCallback) {
            this.props.returnCallback(this.props.context, this.props.propertyName as keyof T, value);
        }
    }

    populateFormControl() {
        return (
            <div>
                {this.state.value?.length !== 0 && <div  id="imagegridformfield" className="form-control-feedback-container form-control" style={{ height: "100%" }}>
                    <Container>
                        {this.state.value?.filter((thumbnail: any, thumbnailIndex) => thumbnailIndex % 3 === 0).map((thumbnail, rowIndex) => {
                            return (<Row className="show-grid" key={rowIndex}>
                                {this.state.value?.slice(rowIndex * 3, rowIndex * 3 + 3).map((thumbnail: any, columnIndex) => {
                                    const value = this.state.value ? this.state.value[rowIndex * 3 + columnIndex] : null;

                                    return thumbnail && (
                                        <Col key={thumbnail.filename} xs={6} md={4} className="text-center" style={{display: "flex", flexDirection:"column", justifyContent: "space-between" }}>
                                                <div style={{position: "relative"}}>
                                                    <Image src={thumbnail.url} thumbnail />
                                                    <FontAwesomeIcon icon={faClose} className="icon" style={{position: "absolute", top: "10px", right: "10px" }} onClick={() => this.onDeleteThumbnail(thumbnail.filename)}/>
                                                </div>
                                                <TagInput
                                                    tagValues={value?.tags}
                                                    placeholder={this.props.placeholder}
                                                    disabled={this.props.disabled}
                                                    options={this.props.optionsList}
                                                    singleValue={true}
                                                    returnCallback={(tagList: string[]) => this.handleTagChange(tagList, rowIndex * 3 + columnIndex)} />
                                        </Col>)
                                })}
                            </Row>)
                        })}
                    </Container>
                </div>}
                <InputGroup style={{justifyContent: "center", marginTop: "40px"}} className="custom-file-button">
                    <label className="input-group-text" style={{background: "linear-gradient(#89b659, #73a839 60%, #6c9e36)", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", color: "white"}} htmlFor="inputGroupFile">Add Media Asset</label>
                    <FormControl
                        id="inputGroupFile"
                        type="file"
                        hidden={true}
                        accept=".png,.jpg"
                        onChange={(e) => this.handleChange(((e.currentTarget as any) as HTMLInputElement).files)} />
                </InputGroup>
            </div>
        );
    }
}

interface MonetizationRadioFormFieldProps<T> extends FormFieldProps<boolean, T> {
    trueValue: string;
    falseValue: string;
    warning: string;
    displayModal: boolean;
    useModal: boolean;
}

export class MonetizationRadio<T> extends FormField<boolean, T, MonetizationRadioFormFieldProps<T>> {
    constructor(props: MonetizationRadioFormFieldProps<T>) {
        super(props);
        this.passthroughChange = this.passthroughChange.bind(this);
        this.modalReturn = this.modalReturn.bind(this);
    }

    getInitialState() {
        var initialState = super.getInitialState();
        initialState.displayModal = false;
        return initialState;
    }

    modalReturn() {
        this.setState(() => ({ displayModal: false }));
    }

    passthroughChange(value: boolean) {
        if (this.props.useModal === true && value === false) {
            this.setState(() => ({ displayModal: true }));
        }

        if (this.props.useModal === true && value === true) {
            this.setState(() => ({ displayModal: false }));
        }

        this.handleChange(value);

    }


    populateFormControl() {

        var modal = <div></div>;
        if (this.state.displayModal === true) {
            var modalBody = <div>
                <p style={{ fontSize: 15, color: "Red" }}>Select <strong>Free</strong> ONLY if you are approved to make this a free piece of content. <br /> This submission will be rejected by content review if you are not approved to make this piece of content free!</p>
            </div>
            modal = <CustomModal title="Warning!" body={modalBody} callbackFromParent={this.modalReturn} buttonText="CONTINUE"></CustomModal>
        }
        if (this.state.value === true) {
            return (
                <div>
                    {modal}
                    <div>
                        <FormCheck
                            name="ValidationRadio"
                            value="yesRadio"
                            onChange={() => this.passthroughChange(true)}
                            defaultChecked
                            inline> {this.props.trueValue}</FormCheck>{' '}
                        <FormCheck
                            name="ValidationRadio"
                            value="noRadio"
                            onChange={() => this.passthroughChange(false)}
                            inline> {this.props.falseValue} </FormCheck>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {modal}
                    <div>
                        <FormCheck
                            name="ValidationRadio"
                            value="yesRadio"
                            onChange={() => this.passthroughChange(true)}
                            inline> {this.props.trueValue}</FormCheck>{' '}
                        <FormCheck
                            name="ValidationRadio"
                            value="noRadio"
                            onChange={() => this.passthroughChange(false)}
                            defaultChecked
                            inline> {this.props.falseValue} </FormCheck>&nbsp;&nbsp;&nbsp;
                    </div>
                    <div style={{ paddingTop: 10 }}>{this.props.warning}</div>
                </div>
            )
        }

    }
}

interface GenericRadioFormFieldProps<V, T> extends FormFieldProps<V, T> {
    radioGroupName: string;
    radioFields: {
        name: string;
        value: string;
    }[];
}

export class GenericRadioForm<V, T> extends FormField<V, T, GenericRadioFormFieldProps<V, T>, FormFieldState<V>> {

    populateFormControl() {
        var radioComponents: ReactElement[] = [];
        this.props.radioFields.forEach(element => {
            var radioElement =
                <FormCheck
                    key={element.value}
                    name={this.props.radioGroupName}
                    value={element.value}
                    disabled={this.props.disabled}
                    checked={this.state.value === element.value}
                    onChange={() => this.handleChange(element.value as V)}
                    inline> {element.name} </FormCheck>
            radioComponents.push(radioElement);
        })

        return (
            <div>
                <div>
                    {radioComponents}
                </div>
            </div>
        );
    }
}

interface GenericCheckboxFormProps<T> extends FormFieldProps<boolean, T> {
    checkboxFields: {
        name: string;
        value: boolean;
    }[];
}

export class GenericCheckboxForm<T> extends FormField<boolean, T, GenericCheckboxFormProps<T>> {

    populateFormControl() {
        var checkboxComponents: ReactElement[] = [];
        this.props.checkboxFields.forEach(element => {
            var checkboxElement =
                <FormCheck style={{ whiteSpace: "nowrap" }}
                    key={element.name}
                    label={element.name}
                    value={element.value ? "true" : "false"}
                    disabled={this.props.disabled}
                    checked={this.state.value === element.value}
                    onChange={(e) => this.handleChange(!this.state.value)}
                    inline/>
            checkboxComponents.push(checkboxElement);
        })

        return (
            <div>
                <div>
                    {checkboxComponents}
                </div>
            </div>
        );
    }
}

interface MetaRadioFormFieldProps<T> extends FormFieldProps<boolean, T> {
    trueValue: string;
    falseValue: string;
}

export class MetaRadioFormField<T> extends FormField<boolean, T, MetaRadioFormFieldProps<T>> {
    populateFormControl() {
        return (
            <div>
                <div>
                    <FormCheck
                        name="MetaDataRadio"
                        value="yesRadio"
                        checked={this.state.value as boolean}
                        onChange={() => this.handleChange(true)}
                        inline> {this.props.trueValue}</FormCheck>{' '}
                    <FormCheck
                        name="MetaDataRadio"
                        value="noRadio"
                        checked={!(this.state.value as boolean)}
                        onChange={() => this.handleChange(false)}
                        inline> {this.props.falseValue} </FormCheck>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        )

    }
}
