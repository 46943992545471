import React, { Component } from 'react';

class CommentsDetails extends Component {

    render() {
        return (
            <div className="comment"><div dangerouslySetInnerHTML={{ __html: this.props.value.text }}></div></div>
        );
    }
}
export { CommentsDetails };