export class Publisher {
    id: string = '';
    _etag?: string;
    pk?: string;
    created?: string;
    modified?: string;
    uaid: string = '';
    sellerId: string = '';
    publisherId: string = '';
    name: string = '';
    partnerPriority: string = '';
    releaseOwner: string = '';
    isApproved: boolean = false;
    developers: string[] = [];
}