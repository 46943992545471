import React, { useEffect, useState } from 'react';
import { Developer } from '../../Models/Developer';
import { Publisher } from '../../Models/Publisher';
import Auth from '../../Auth/Auth';
import ReactTable from '../../ReactTable';
import DeveloperModal from './DeveloperModal';
import { Column, ColumnStyle } from '../../Models/Column';
import { Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Tag } from '../FormFields/TagInput';

interface Props {
    developers: Developer[];
    publishers: Publisher[];
    auth: Auth;
}
  
function DeveloperList(props: Props) {
    const [developers, setDevelopers] = useState<Developer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchEnabled, setSearchEnabled] = useState<boolean>(false);

    const toggleSearch = () => setSearchEnabled(!searchEnabled);

    useEffect(() => {
        if (props.developers === null || props.developers === undefined) {
            setDevelopers([]);
            setLoading(true);
        } else {
            setDevelopers(props.developers);
            setLoading(false);
        }
    }, [props.auth, props.developers]);

    const updateDeveloperList = (newDeveloper: Developer) => {
        if (developers === null || developers === undefined) {
            return [];
        }

        const updateOrAddDeveloper = (): Developer[] => {
            const updatedDevelopers = developers.map(developer =>
              developer.id === newDeveloper.id ? newDeveloper : developer
            );
            if (!developers.some(developer => developer.id === newDeveloper.id)) {
              updatedDevelopers.push(newDeveloper);
            }
            return updatedDevelopers;
          };
        
        setDevelopers(updateOrAddDeveloper());
      };

    const getDeveloperColumnHeaders = ((isNew: boolean) : Column[] => {
        const columnStyle : ColumnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };
    
        const columns : Column[] = [
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Developer Id',
                accessor: 'id',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
                sortable: true
            },
            {
                Header: 'Publishers',
                accessor: 'publishers',
                maxWidth: 200,
                minWidth: 100,
                width: 100,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.publishers && <div className="tags-container">
                        {row.original.publishers.map((publisherId: string, index: any) => { return <Badge key={index} className="tag-badge pill">{publisherId}</Badge> }) }
                    </div>
                )
            },
            {
                Header: 'Approved',
                accessor: 'isApproved',
                maxWidth: 200,
                minWidth: 20,
                width: 20,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.isApproved ? 'Yes' : 'No'
                )
            },
            {
                Header: '',
                accessor: 'edit',
                maxWidth: 10,
                minWidth: 10,
                width: 10,
                style: columnStyle,
                filterable: false,
                sortable: false,
                Cell: (row: any) => (
                    <DeveloperModal auth={props.auth} developer={row.row.original as Developer} isNew={isNew} publishers={props.publishers} updateDeveloperCallback={updateDeveloperList}/>
                )
            }
        ];
        return columns;
    });

    return (
        <div>
            <div className="list-buttons-container">
                <DeveloperModal auth={props.auth} developer={new Developer()} isNew={true} publishers={props.publishers} updateDeveloperCallback={updateDeveloperList}/>
            </div>
            <ReactTable 
                data={developers}
                columns={getDeveloperColumnHeaders(false)}
                loading={loading}
                defaultSorted={
                    [
                        {
                            id: "name",
                            desc: false
                        }
                    ]
                }
                defaultPageSize={20}
                className="-striped -highlight table-container"
                />
        </div>
    );
}

export default DeveloperList;