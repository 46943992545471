
import React, { Component } from 'react';

import { Button, Modal} from 'react-bootstrap';
import * as styles from '../Constants/styles';

class CustomModal extends Component {

    handleButton() {
        this.props.callbackFromParent();
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.handleButton()} backdrop={"static"} keyboard={this.props.keyboard ? true : false}>
                <Modal.Header closeButton={this.props.closeButton ? true : false}>
                    <Modal.Title style={styles.center}>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={styles.center}>
                    {this.props.body}

                    <div style={styles.center} >
                        <Button variant="primary" onClick={() => this.handleButton()}>{this.props.buttonText}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export {
    CustomModal,
} 