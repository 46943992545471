const {
    BlockBlobClient,
    AnonymousCredential,
    newPipeline
} = require("@azure/storage-blob");

export async function uploadBrowserData(url, file, onProgress, onFulfilled, onRejected) {
    var pipeline = newPipeline(new AnonymousCredential());
    const blockBlobClient = new BlockBlobClient(
        url,
        pipeline
    );

    await blockBlobClient.uploadData(file, {
        maxSingleShotSize: 4 * 1024 * 1024,
        onProgress: onProgress
    })
    .then(onFulfilled)
    .catch(onRejected);
}
