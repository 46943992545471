import React, { useEffect, useReducer, useState } from 'react';
import { Button, Card, FormCheck, Modal } from 'react-bootstrap';
import { AUGER_OFFER_TYPES } from '../Constants/enums'
import Auth from '../Auth/Auth';
import { DeleteContentPackage, DeleteMarketplacePackage, GetContentPackageDetails, GetContentPackages, GetMarketplacePackages, GetMarketplacePackageSummary, IngestContentPackage, TransferToPrerelease, TransferToRelease } from '../Api/RomaWebApi';
import { MarketplaceProductForm } from './MarketplaceProductForm';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import { MarketplaceSummary, MisPackageDetailsResponse, PackageDetails, PlatformDetails, PublishingTarget, SubmissionType, getLatestContentPackageDetailsResponse, getLatestMarketplacePackageDetailsResponse, getPackageNameNoPrefix } from '../Models/AugerOffers/MarketplaceDetails';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import ReactTable from '../ReactTable';
import { MarketplaceProductDetails } from './MarketplaceProductDetails';
import { center, warningText } from '../Constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { ContentPackageForm } from './ContentPackageForm';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';

interface Props {
    auth: Auth;
}

export default function MarketplaceProductBrowser(props: Props) {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [marketplacePackage, setMarketplacePackage] = useState<MarketplacePackage>();
    const [contentPackage, setContentPackage] = useState<ContentPackage>();
    const [contentPackagesDetails, setContentPackagesDetails] = useState<PackageDetails<PlatformDetails>[]>([]);
    const [marketplacePackageSummaries, setMarketplacePackageSummaries] = useState<PackageDetails<MarketplaceSummary>[]>([]);
    const [editMarketplacePackage, setEditMarketplacePackage] = useState(false);
    const [deletePackage, setDeletePackage] = useState(false);
    const [uploadContentPackage, setUploadContentPackage] = useState(false);
    const [releasePC, setReleasePC] = useState(false);
    const [releaseXbox, setReleaseXbox] = useState(false);
    const [releaseMarketplace, setReleaseMarketplace] = useState(false);
    const [transferToRelease, setTransferToRelease] = useState(false);
    const [ignoreWorkItem, setIgnoreWorkItem] = useState(false);
    const [releasePackages, setReleasePackages] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNew, setIsNew] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        GetContentPackages(props.auth).then((result) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setContentPackagesDetails([]);
                setError("No offers found");
            } else {
                setContentPackagesDetails(result);
            }
        }).catch((error) => {
            console.log("error", error);
            setContentPackagesDetails([]);
            setError(error);
        });

        GetMarketplacePackages(props.auth).then((result: PackageDetails<MarketplaceSummary>[]) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setMarketplacePackageSummaries([]);
                setError("No offers found");
            } else {
                setMarketplacePackageSummaries(result);
            }

            setLoading(false);
        }).catch((error) => {
            console.log("error", error);
            setMarketplacePackageSummaries([]);
            setError(error);
            setLoading(false);
        });
    }, [props.auth]);

    function updateContentPackageDetails(packageName: string) {
        GetContentPackageDetails(props.auth, packageName).then((result: PackageDetails<PlatformDetails>) => {
            const index = contentPackagesDetails.findIndex((contentPackage) => contentPackage.packageName === packageName);
            contentPackagesDetails[index] = result;
            forceUpdate();
        }).catch((error) => {
            console.log("error", error);
            setError(error);
        });
    }

    function updateMarketplaceSummary(packageName: string, index: number) {
        GetMarketplacePackageSummary(props.auth, packageName).then((result: PackageDetails<MarketplaceSummary>) => {
            marketplacePackageSummaries[index] = result;
            forceUpdate();
        }).catch((error) => {
            console.log("error", error);
            setError(error);
        });
    }

    function processMarketplaceFormInfo(submission: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage , propertyValue?: any) { 
        if (propertyValue == null) {
            return;
        } else if (propertyName === "packageName" && propertyValue === "Upload new Content Package...") {
            setContentPackage(AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth }) as ContentPackage);
            setUploadContentPackage(true);            
            return;
        }
    
        submission?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    function processContentFormInfo(submission: ContentPackage | undefined, propertyName: keyof ContentPackage, propertyValue?: any) { 
        if (propertyValue == null || propertyName === "submissionId" || propertyName === "publishingTarget"){
            return;
        }
    
        if (propertyName === "packageName") {
            const publisherId = propertyValue.split('-')[0];
            marketplacePackage?.setFieldFromName("publisherId" as keyof MarketplaceItem, publisherId);
        }

        marketplacePackage?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    function onNew() {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { auth: props.auth, marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;

        setMarketplacePackage(marketplacePackage);
        setIsNew(true);
        setEditMarketplacePackage(true);
    }

    function onEdit(data: PackageDetails<MarketplaceSummary>) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { ...getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data), marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;

        setEditMarketplacePackage(true);
        setIsNew(false);
        setMarketplacePackage(marketplacePackage);
    }

    function onEditContentPackage(index: number, data: PackageDetails<MarketplaceSummary>) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { ...getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data), marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === getPackageNameNoPrefix(marketplacePackage.packageName.value));
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth, ...contentPackageDetails }) as ContentPackage;

        setMarketplacePackage(marketplacePackage);
        setContentPackage(contentPackage)
        setIsNew(false);
        setUploadContentPackage(true);
    }

    function onDeleteContentPackage(index: number, data: PackageDetails<MarketplaceSummary>) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { ...getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data), marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === getPackageNameNoPrefix(marketplacePackage.packageName.value));
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth, ...contentPackageDetails }) as ContentPackage;

        setMarketplacePackage(marketplacePackage);
        setContentPackage(contentPackage)
        setIsNew(false);
        setDeletePackage(true);
    }

    async function onSaveContentPackage() {
        setUploadContentPackage(false);

        if (!isNew && marketplacePackage && contentPackage) {
            await IngestContentPackage(props.auth, {
                submissionId: contentPackage.submissionId.value as string,
                packageName: contentPackage.packageName.value as string,
                publishingTarget: contentPackage.publishingTarget.value as PublishingTarget,
                publisherId: marketplacePackage.publisherId.value as string,
                developerId: marketplacePackage.developerId.value as string
            }).catch((error) => {
                console.log("error", error);
                setError(error);
            });

            forceUpdate();
        }
    }

    function onReleaseRequest(index: number, data: any) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { ...getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data), marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;
        const marketplacePackageSummary = marketplacePackageSummaries.find((mp) => mp.packageName === getPackageNameNoPrefix(marketplacePackage.packageName.value));
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === getPackageNameNoPrefix(marketplacePackage.packageName.value));
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, contentPackageDetails) as ContentPackage;

        if (marketplacePackageSummary && contentPackageDetails) {
            const latestMarketplacePackageDetails = getLatestMarketplacePackageDetailsResponse(marketplacePackageSummary);
            const latestContentPackageDetails = getLatestContentPackageDetailsResponse(contentPackageDetails);
            
            if (latestMarketplacePackageDetails.releaseStatus === SubmissionType.Draft) {
                setReleaseMarketplace(true);
            }

            if (latestContentPackageDetails.PC.releaseStatus === SubmissionType.Draft) {
                setReleasePC(true);
            }

            if (latestContentPackageDetails.Xbox.releaseStatus === SubmissionType.Draft) {
                setReleaseXbox(true);
            }
        }

        setMarketplacePackage(marketplacePackage);
        setContentPackage(contentPackage);
        setTransferToRelease(false);
        setReleasePackages(true);
    }

    function onConfirmReleaseRequest(confirm: boolean) {
        if (confirm && marketplacePackage) {
            const publishingTargets:PublishingTarget[] = [];
            
            if (releasePC) {
                publishingTargets.push(PublishingTarget.PC);
            }

            if (releaseXbox) {
                publishingTargets.push(PublishingTarget.Xbox);
            }

            if (transferToRelease) {
                TransferToRelease(props.auth, {
                    packageName: marketplacePackage.packageName.value as string,
                    developerId: marketplacePackage.developerId.value as string,
                    publisherId: marketplacePackage.publisherId.value as string,
                    marketplaceRelease: releaseMarketplace,
                    contentRelease: releasePC || releaseXbox,
                    publishingTargets: publishingTargets
                });
            } else {
                TransferToPrerelease(props.auth, {
                    packageName: marketplacePackage.packageName.value as string,
                    developerId: marketplacePackage.developerId.value as string,
                    publisherId: marketplacePackage.publisherId.value as string,
                    marketplaceRelease: releaseMarketplace,
                    contentRelease: releasePC || releaseXbox,
                    publishingTargets: publishingTargets,
                }, ignoreWorkItem);
            }

            setMarketplacePackage(undefined);
        }

        setReleasePackages(false);
    }

    async function onConfirmDeletePackage(confirm: boolean) {
        if (confirm) {
            if (marketplacePackage) {
                if (contentPackage?.packageName.value) {
                    try {
                        await DeleteContentPackage(props.auth, contentPackage.packageName.value, contentPackage.publishingTarget.value ?? PublishingTarget.PCAndXbox, contentPackage.publisherId.value as string, contentPackage.developerId.value as string);
                    } catch (error) {
                        console.log("Error deleting content package: ", error);
                    }

                    setContentPackage(undefined);
                }

                try {
                    await DeleteMarketplacePackage(props.auth, getPackageNameNoPrefix(marketplacePackage.packageName.value) as string, marketplacePackage.publisherId.value as string, marketplacePackage.developerId.value as string);
                } catch (error) {
                    console.log("Error deleting marketplace package: ", error);
                }

                setMarketplacePackage(undefined);
            } else {
                setContentPackage(undefined);
                setMarketplacePackage(undefined);
            }
        }

        setDeletePackage(false);
    }

    async function onSave() {
        if (isNew && marketplacePackage && contentPackage) {
            await IngestContentPackage(props.auth, {
                submissionId: contentPackage.submissionId.value as string,
                packageName: contentPackage.packageName.value as string,
                publishingTarget: contentPackage.publishingTarget.value as PublishingTarget,
                publisherId: marketplacePackage.publisherId.value as string,
                developerId: marketplacePackage.developerId.value as string
            }).catch((error) => {
                console.log("error", error);
                setError(error);
            });
        }

        setEditMarketplacePackage(false);
    }

    function onCancel() {
        setEditMarketplacePackage(false);
        setMarketplacePackage(undefined);
        setContentPackage(undefined);
    }

    function subComponent (row: any) {
        const submission = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { ...getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original), marketplacePackageSummaries: marketplacePackageSummaries, contentPackagesDetails: contentPackagesDetails }) as MarketplacePackage;
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => getPackageNameNoPrefix(contentPackage.packageName) === getPackageNameNoPrefix(submission.packageName.value));
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth, ...contentPackageDetails }) as ContentPackage;

        return <MarketplaceProductDetails auth={props.auth} isNew={false} onSave={onSave} onCancel={onCancel} marketplacePackage={submission} marketplacePackageSummary={row.original} marketplacePackageSummaries={marketplacePackageSummaries} processFormInfo={processMarketplaceFormInfo} contentPackage={contentPackage} contentPackagesDetails={contentPackagesDetails?.map((submission:any) => submission.packageName)} />
    }

    const isInternal = props.auth.isInternal();
    const columns = MarketplacePackage.getColumnHeaders(onEdit, onReleaseRequest, onDeleteContentPackage, onEditContentPackage, updateMarketplaceSummary, isInternal, props.auth.isAdministrator());
    const noDataText = "No offers found!";

        //loading
    const lastPageSize = JSON.parse(localStorage.getItem('lastBrowserPageSize') ?? "10");

    let lastFilter:string | null | undefined = localStorage.getItem('lastBrowserFilter');

    if (lastFilter && lastFilter !== 'undefined') {
        lastFilter = JSON.parse(lastFilter);
    } else {
        lastFilter = undefined;
    }

    let lastSorted:string | null | undefined = localStorage.getItem('lastBrowserSort');

    if (lastSorted && lastSorted !== 'undefined') {
        lastSorted = JSON.parse(lastSorted);

        if (lastSorted?.length === 0) {
            lastSorted = undefined;
        }
    } else {
        lastSorted = undefined;
    }

    const customFilter = (rows: any[], globalFilterValue: string) => {
        const filterValues = globalFilterValue.split(',');
        const result = rows.filter(row => {
            const details = getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original)
            const result = details && filterValues.find(fv => Object.values(details).find(value => typeof value === 'string' && value?.toLowerCase().includes(fv.trim().toLowerCase())));
            return result?.trim();
        });
        
        return result;
    }

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {marketplacePackage && <Modal centered show={editMarketplacePackage} dialogClassName="modal-70w" onHide={() => setEditMarketplacePackage(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Body>
                            <h1 style={center}>{isNew ? "New Marketplace Product" : "Edit Marketplace Product"}</h1>
                            <div style={center}><em>Note: This is a helpful note.</em></div>
                            <MarketplaceProductForm key={marketplacePackage.submissionId.value} auth={props.auth} isNew={isNew} validate={!uploadContentPackage} onSave={onSave} onCancel={onCancel} processFormInfo={processMarketplaceFormInfo} marketplacePackage={marketplacePackage} marketplacePackageSummaries={marketplacePackageSummaries} contentPackagesDetails={contentPackagesDetails} />
                        </Modal.Body>
                    </Modal>}
                    {contentPackage && <ContentPackageForm key={contentPackage.submissionId.value} show={uploadContentPackage} onSave={async () => await onSaveContentPackage()} onHide={() => setUploadContentPackage(false)} auth={props.auth} processFormInfo={processContentFormInfo} isNew={isNew} contentPackage={contentPackage}/>}
                    <Modal show={releasePackages} onHide={() => setReleasePackages(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Header>
                            <Modal.Title style={center}>Request Release</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={center} >
                                <h3 style={warningText}>This operation cannot be undone.</h3>
                                    <div>
                                        {props.auth.isInternal() && <div>
                                            <FormCheck
                                                key={"transferToRelease"}
                                                label={"Transfer to Release"}
                                                checked={transferToRelease}
                                                onChange={() => { setTransferToRelease(!transferToRelease) }}
                                                inline/>
                                            <FormCheck
                                                key={"ignoreWorkItem"}
                                                label={"Ignore Work Item"}
                                                checked={ignoreWorkItem}
                                                onChange={() => { setIgnoreWorkItem(!ignoreWorkItem) }}
                                                inline/>
                                        </div>}
                                        <FormCheck
                                            key={"PC"}
                                            label={"PC"}
                                            checked={releasePC}
                                            onChange={() => { setReleasePC(!releasePC) }}
                                            inline/>
                                        <FormCheck
                                            key={"Xbox"}
                                            label={"Xbox"}
                                            checked={releaseXbox}
                                            onChange={() => { setReleaseXbox(!releaseXbox) }}
                                            inline/>
                                        <FormCheck
                                            key={"Marketplace"}
                                            label={"Marketplace"}
                                            checked={releaseMarketplace}
                                            onChange={() => { setReleaseMarketplace(!releaseMarketplace) }}
                                            inline/>
                                    </div>
                                <span>
                                <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={deletePackage} onHide={() => setDeletePackage(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Header>
                            <Modal.Title style={center}>Delete Package</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                    <div style={center} >
                                        <h3 style={warningText}>Are you sure?</h3>
                                        <span>
                                        <Button style={{margin: 10}} onClick={async () => await onConfirmDeletePackage(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                        <Button style={{margin: 10}} onClick={async () => await onConfirmDeletePackage(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                        </Modal.Body>
                    </Modal>
                    <Card className="text-center">
                        <div style={center} >
                            <Button variant="success" style={{display: "display-inline", margin: "10px"}} size="sm" disabled={loading} onClick={() => { onNew() }}>CREATE NEW MARKETPLACE PRODUCT</Button>
                            <div>
                                <em>Tip: Hold shift when sorting to multi-sort!</em>
                                <ReactTable
                                    onSortedChange={(newSorted: string) => {
                                        if (newSorted) {
                                            localStorage.setItem('lastBrowserSort', JSON.stringify(newSorted));
                                        }
                                        }}
                                    onFilteredChange={(filtered:boolean) => {
                                        if (filtered) {
                                            localStorage.setItem('lastBrowserFilter', JSON.stringify(filtered));
                                        } else {
                                            localStorage.removeItem('lastBrowserFilter');
                                        }
                                    }}
                                    onPageSizeChange={(pageSize:string) => {
                                        if (pageSize) {
                                            localStorage.setItem('lastBrowserPageSize', pageSize);
                                        }
                                    }}
                                    style={{clear:'right'}}
                                    data={marketplacePackageSummaries}
                                    columns={columns}
                                    loading={loading}
                                    SubComponent={subComponent}
                                    minRows={0}
                                    noDataText = {noDataText}
                                    defaultSorted={lastSorted ?? (isInternal ? MarketplacePackage.defaultFirstPartyListSorting : MarketplacePackage.defaultListSorting)}
                                    defaultFiltered={(lastFilter) ? lastFilter : undefined}
                                    defaultPageSize={lastPageSize ?? 10}
                                    className="-striped -highlight"
                                    customFilter={customFilter}
                                />
                            </div>
                        </div>
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}