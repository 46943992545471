
import React, { Component } from 'react';

import { Button, FormGroup, FormControl, FormLabel, FormText, OverlayTrigger, Popover } from 'react-bootstrap';

import { MAX_UPLOAD_SIZE } from '../../Constants/enums';

const popoverTop = (
    <Popover id="popover-positioned-top" >
        <p>In order to have a valid submission your Content needs to exactly follow our Packaging Documentation. There is a folder structure and naming conventions that you must adhere to.</p>
    </Popover>
);

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            default: this.props.value,
            maxSize: MAX_UPLOAD_SIZE,
            hasValidationPrompt: this.props.hasValidationPrompt
        };

        this.props.callbackFromParent("FileUpload", this.props.value, "error");

        this.getValidationState = this.getValidationState.bind(this);
    }

    getValidationState() {
        return this.validateFile(this.state.value);
    }

    validateFile(file) {
        if (file !== null) {
            if (file.size < this.state.maxSize) {
                var extension = file.name.split('.').pop();
                if (extension === "zip") {
                    return "success";
                }
            }
        }

        return "error";
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            if (this.props.value === null) {
                this.setState(() => ({ value: null }));
                this.props.callbackFromParent("FileUpload", null, "error");
            }
        }

    }

    resetToDefault() {
        this.setState(() => ({ value: this.state.default }));
    }



    updateFiles(updateFiles) {
        if (updateFiles.length === 1) {
            this.setState(() => ({ value: updateFiles[0] }));

            var validationState = this.validateFile(updateFiles[0]);
            this.props.callbackFromParent("FileUpload", updateFiles[0], validationState);
        }
        else {
            this.setState(() => ({ value: null }));
            this.props.callbackFromParent("FileUpload", null, "error");
        }
    }

    render() {

        var sizeInMb = MAX_UPLOAD_SIZE / 1024 / 1024;

        var inputForm = <FormControl
            type="file"
            placeholder="Choose File"
            accept=".zip"
            style={{ width: "100%" }}
            onChange={(e) => this.updateFiles(e.target.files)}>
        </FormControl>
        if (this.state.value === null) {
            inputForm = <FormControl
                type="file"
                placeholder="Choose File"
                accept=".zip"
                style={{ width: "100%" }}
                value=""
                onChange={(e) => this.updateFiles(e.target.files)}>
            </FormControl>
        }

        return (
            <FormGroup
                controlId="formControlsFile"
                validationState={this.getValidationState()}
                className="file-upload"
            >
                <FormControl.Feedback />
                <FormLabel>Attach Your File</FormLabel>
                <div className="form-control-feedback-container form-control">
                    <FormText>

                        <p className="message">Upload a zip with a maximum file size of {sizeInMb}MB&nbsp; -<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverTop}>
                            <Button tabIndex={-1} size="sm" variant="link">More Info</Button>
                        </OverlayTrigger></p>

                        {this.state.hasValidationPrompt &&
                            <p className="message">Run our validation tool on your content before you submit.&nbsp; - &nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://-Validation-Tool">Validation Tool</a>
                            </p>}
                    </FormText>

                    {inputForm}
                </div>
            </FormGroup>
        );
    }
}

export {
    FileUpload,
};